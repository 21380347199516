var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DiscountManager, getAvailableAgreements, getAvailableDiscountRates, InitDiscountRates } from 'hub-lib/business/DiscountManager.bin';
import { Client } from 'hub-lib/client/client.bin';
import { eKPI, eKPIType, KPIsManagerCache } from 'hub-lib/models/KPIsManager.bin';
import { lnk_AdvertisingCompanySupport } from 'hub-lib/models/orientdb/lnk_AdvertisingCompanySupport.bin';
import { lnk_HasBroadcastArea } from 'hub-lib/models/orientdb/lnk_HasBroadcastArea.bin';
import { ref_AdvertisingCompanies } from 'hub-lib/models/orientdb/ref_AdvertisingCompanies.bin';
import { ref_AdvertisingCompanyRole } from 'hub-lib/models/orientdb/ref_AdvertisingCompanyRole.bin';
import { ref_BroadcastAreas } from 'hub-lib/models/orientdb/ref_BroadcastAreas.bin';
import { ref_Currencies } from 'hub-lib/models/orientdb/ref_Currencies.bin';
import { ref_SubAgencies } from 'hub-lib/models/orientdb/ref_SubAgencies.bin';
import { ref_Supports } from 'hub-lib/models/orientdb/ref_Supports.bin';
import { clone, compareObjects, distinct, duplicate, extractSub, firstOrDefault, SerializeError } from 'hub-lib/tools.bin';
import { Trad } from 'trad-lib';
import { getDefaultFormatOffer, getFormatOffer } from '../components/VertexGrid/Messages/MessageEditor/OfferTools';
import { FilterStorage, GetUser } from '../utils/localstorage.bin';
import { Notify } from '../utils/Notify.bin';
import { GetMessageOffers, SetBroadcastPriceOffer, SetPriceOffer } from '../utils/Tools';
import { DateZone } from 'tools-lib';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { ref_Agreements } from 'hub-lib/dto/client/ref_Agreements.bin';
import { eGroupCategories } from 'hub-lib/dto/client/ref_Groups.bin';
import { ref_GlobalAgreements } from 'hub-lib/dto/client/ref_GlobalAgreements.bin';
import { ref_Campaigns } from 'hub-lib/dto/client/ref_Campaigns.bin';
import { GetKPITemplate } from 'format-lib/index.bin';
import { OnSupportChange, SetGroup, SetPerformancesKPIFromOJD, UpdateAgencyState, UpdateBroadcastState, UpdateCurrencyState } from './storeUtils';
import BigNumber from 'bignumber.js';
import { ToRefCampaign, ToRefCampaignSerializable } from './campaignEditorSlice';
import { eRights, RightManager } from 'hub-lib/models/types/rights.bin';
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin';
import { eDialogMode } from '../components/ConfigurableComponents/GenericDialog.bin';
function ToRefMessage(message) { return __assign(__assign({}, duplicate(message)), { Start: message.Start ? new Date(message.Start) : undefined, End: message.End ? new Date(message.End) : undefined, CancellationTimeLimit: message.CancellationTimeLimit ? new Date(message.CancellationTimeLimit) : undefined, ConfirmationTimeLimit: message.ConfirmationTimeLimit ? new Date(message.ConfirmationTimeLimit) : undefined, TechDeliveryTimeLimit: message.TechDeliveryTimeLimit ? new Date(message.TechDeliveryTimeLimit) : undefined }); }
function ToRefMessageSerializable(message) { return __assign(__assign({}, duplicate(message)), { Start: message.Start ? new Date(message.Start).getTime() : undefined, End: message.End ? new Date(message.End).getTime() : undefined }); }
var initialState = { mode: undefined, currentNetType: "CO", cascade: null, currentAgreement: undefined, globalAgreement: null, agreements: null, lockNext: false, validationIssues: [], repeat: false, currencyCode: "", currency: undefined, data: undefined, offers: undefined, offer: undefined, offerFormat: undefined, offerEmpl: undefined, publications: undefined, agencyOptions: undefined, currencyOptions: undefined, broadcastOptions: undefined, AdvCompany_ComOptions: undefined, AdvCompany_FinOptions: undefined, clntDiscountRates: undefined, kpis: undefined, campaign: undefined, groups: { MediaFamily: [], PlacementCategory: [] }, get: function () { return this.data && ToRefMessage(this.data); }, getCampaign: function () { return this.campaign && ToRefCampaign(this.campaign); }, getOffers: function () { var _this$offers$map, _this$offers; return (_this$offers$map = (_this$offers = this.offers) === null || _this$offers === void 0 ? void 0 : _this$offers.map(function (o) { return (__assign(__assign({}, duplicate(o)), { Start: o.Start ? new Date(o.Start) : undefined, End: o.End ? new Date(o.End) : undefined })); })) !== null && _this$offers$map !== void 0 ? _this$offers$map : []; } };
export var initAdvComOptions = createAsyncThunk('messageEditor/initAdvComOptions', function (_, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () { var state, AdvCompany_ComOptions, error_1; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0:
            _a.trys.push([0, 2, , 3]);
            state = thunkAPI.getState();
            return [4 /*yield*/, UpdateAdvertisingCompany("AdvCompany_Com", state.messageEditor, state.messageEditor.get())];
        case 1:
            AdvCompany_ComOptions = _a.sent();
            return [2 /*return*/, { AdvCompany_ComOptions: AdvCompany_ComOptions }];
        case 2:
            error_1 = _a.sent();
            console.error(error_1);
            return [3 /*break*/, 3];
        case 3: return [2 /*return*/];
    }
}); }); });
export var initAdvFinOptions = createAsyncThunk('messageEditor/initAdvFinOptions', function (_, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () { var state, AdvCompany_FinOptions, error_2; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0:
            _a.trys.push([0, 2, , 3]);
            state = thunkAPI.getState();
            return [4 /*yield*/, UpdateAdvertisingCompany("AdvCompany_Fin", state.messageEditor, state.messageEditor.get())];
        case 1:
            AdvCompany_FinOptions = _a.sent();
            return [2 /*return*/, { AdvCompany_FinOptions: AdvCompany_FinOptions }];
        case 2:
            error_2 = _a.sent();
            console.error(error_2);
            return [3 /*break*/, 3];
        case 3: return [2 /*return*/];
    }
}); }); });
export var initMessageAgencyOptions = createAsyncThunk('messageEditor/initAgencyOptions', function (_, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () { var state, agencyOptions, error_3; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0:
            _a.trys.push([0, 2, , 3]);
            state = thunkAPI.getState();
            return [4 /*yield*/, UpdateAgencyState(state.messageEditor, state.messageEditor.get())];
        case 1:
            agencyOptions = _a.sent();
            return [2 /*return*/, { agencyOptions: agencyOptions }];
        case 2:
            error_3 = _a.sent();
            console.error(error_3);
            return [3 /*break*/, 3];
        case 3: return [2 /*return*/];
    }
}); }); });
export var initMessageCurrencyOptions = createAsyncThunk('messageEditor/initCurrencyOptions', function (_, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () { var state, currencyOptions, error_4; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0:
            _a.trys.push([0, 2, , 3]);
            state = thunkAPI.getState();
            return [4 /*yield*/, UpdateCurrencyState(state.messageEditor, state.messageEditor.get())];
        case 1:
            currencyOptions = _a.sent();
            return [2 /*return*/, { currencyOptions: currencyOptions }];
        case 2:
            error_4 = _a.sent();
            console.error(error_4);
            return [3 /*break*/, 3];
        case 3: return [2 /*return*/];
    }
}); }); });
export var initMessageBroadcastOptions = createAsyncThunk('messageEditor/initBroadcastOptions', function (_, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () { var state, broadcastOptions, error_5; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0:
            _a.trys.push([0, 2, , 3]);
            state = thunkAPI.getState();
            return [4 /*yield*/, UpdateBroadcastState(state.messageEditor, state.messageEditor.get())];
        case 1:
            broadcastOptions = _a.sent();
            return [2 /*return*/, { broadcastOptions: broadcastOptions }];
        case 2:
            error_5 = _a.sent();
            console.error(error_5);
            return [3 /*break*/, 3];
        case 3: return [2 /*return*/];
    }
}); }); });
export function UpdateSPQ(m) { var rateGrossDefaultFormat = Math.round(100 * m.KPIs.Gross / m.KPIs.PriceDefaultFormat) / 100; var rateGrossValoDefaultFormat = Math.round(100 * m.KPIs.GrossVal / m.KPIs.PriceDefaultFormat) / 100; m.KPIs.RateGrossDefaultFormat = Number.isNaN(rateGrossDefaultFormat) ? 0 : rateGrossDefaultFormat; m.KPIs.RateGrossValoDefaultFormat = Number.isNaN(rateGrossValoDefaultFormat) ? 0 : rateGrossValoDefaultFormat; }
var ConsoleDebug = function (message) { for (var _len = arguments.length, optionalParams = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    optionalParams[_key - 1] = arguments[_key];
} if (localStorage.getItem('debugSetMessage'))
    console.log.apply(console, __spreadArray(["[debugSetMessage] ".concat(message)], optionalParams, false)); };
export var setNet = createAsyncThunk('messageEditor/setNet', function (props, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var state, message, campaign, bbaDiscounts, discounts, messageCascade, _row$mode, _row$mode2, base_1, value, mode, row_1, classes, discountClass, _cofoData$Value, _cofoData$Rate, cofoData, cascade_1, getRowBase_1, calcBase_1, position, length, i, _cofoData$Value2, _r$mode, _ref, r, cofoData, rank_1, rows, maxRank, _loop_1, _cofoData$Value3, _cofoData$Rate2, i, _i, _a, r, _cofoData$Value4, _cofoData$Rate3, cofoData, calcDiff, newValue, fixedNewValue, rate, newValue, fixedNewValue, error_6;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                console.log("[setNet] BEGIN", props);
                state = thunkAPI.getState();
                message = state.messageEditor.get();
                campaign = state.messageEditor.getCampaign();
                return [4 /*yield*/, DiscountManager.getCategoryTypes()];
            case 1:
                bbaDiscounts = (_b.sent())[1];
                discounts = message.Discounts.filter(function (d) { return bbaDiscounts.includes(d.DiscountType); });
                messageCascade = null;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 7, , 8]);
                base_1 = props.base, value = props.value, mode = props.mode;
                row_1 = discounts.find(function (r) { return r.DiscountClass == base_1["@rid"]; });
                console.log("[setNet] Row exists", row_1);
                if (!!row_1) return [3 /*break*/, 4];
                return [4 /*yield*/, DiscountManager.GetDiscountClasses()];
            case 3:
                classes = _b.sent();
                discountClass = classes.find(function (c) { return c["@rid"] == base_1["@rid"]; });
                row_1 = { "@rid": null, DiscountClass: base_1["@rid"], DiscountType: base_1.DiscountType, IsRate: true, Rank: 0, Operator: discountClass.Operator, LastPosition: discountClass.LastPosition, Intervals: null };
                message.Discounts.push(row_1);
                _b.label = 4;
            case 4:
                if (!row_1[mode]) {
                    row_1.IsRate = true;
                    cofoData = DiscountManager.getModulation(row_1, mode);
                    row_1[mode] = { Value: (_cofoData$Value = cofoData.Value) !== null && _cofoData$Value !== void 0 ? _cofoData$Value : 0, Rate: (_cofoData$Rate = cofoData.Rate) !== null && _cofoData$Rate !== void 0 ? _cofoData$Rate : 0 };
                }
                console.log("[setNet] row[mode]?.Value", (_row$mode = row_1[mode]) === null || _row$mode === void 0 ? void 0 : _row$mode.Value, ((_row$mode2 = row_1[mode]) === null || _row$mode2 === void 0 ? void 0 : _row$mode2.Value) != 0);
                return [4 /*yield*/, DiscountManager.UpdateCascade(message, campaign)];
            case 5:
                cascade_1 = (_b.sent())[mode];
                getRowBase_1 = function (discountClass) { return cascade_1.find(function (r) { return r.Discount.DiscountClass == discountClass; }); };
                console.log("[setNet] cascade", cascade_1);
                if (getRowBase_1(row_1.DiscountClass).Base != 0) {
                    calcBase_1 = value;
                    if (message.DiscountMode == "cascade") {
                        position = discounts.indexOf(row_1);
                        length = discounts.length;
                        for (i = length - 1; i > position; i--) {
                            r = discounts[i];
                            cofoData = DiscountManager.getModulation(r, mode);
                            if (!r.IsRate)
                                calcBase_1 += (_cofoData$Value2 = cofoData === null || cofoData === void 0 ? void 0 : cofoData.Value) !== null && _cofoData$Value2 !== void 0 ? _cofoData$Value2 : 0;
                            else if (((_r$mode = r[mode]) === null || _r$mode === void 0 ? void 0 : _r$mode.Rate) != 1)
                                calcBase_1 = new BigNumber(calcBase_1 / ((_ref = 1 - (cofoData === null || cofoData === void 0 ? void 0 : cofoData.Rate)) !== null && _ref !== void 0 ? _ref : 0)).dp(2, 4).toNumber();
                        }
                    }
                    else {
                        rank_1 = Number(row_1.Rank);
                        rows = discounts.filter(function (r) { return Number(r.Rank) >= rank_1 && r != row_1; });
                        maxRank = Math.max.apply(Math, rows.map(function (r) { return Number(r.Rank); }));
                        console.log("[setNet] Rows", rows);
                        _loop_1 = function (i) {
                            var rankRows = rows.filter(function (r) { return r.Rank == i; });
                            var rates = 0;
                            for (var _c = 0, rankRows_1 = rankRows; _c < rankRows_1.length; _c++) {
                                var r = rankRows_1[_c];
                                var cofoData = DiscountManager.getModulation(r, mode);
                                if (!r.IsRate)
                                    calcBase_1 += (_cofoData$Value3 = cofoData === null || cofoData === void 0 ? void 0 : cofoData.Value) !== null && _cofoData$Value3 !== void 0 ? _cofoData$Value3 : 0;
                                else
                                    rates += (_cofoData$Rate2 = cofoData === null || cofoData === void 0 ? void 0 : cofoData.Rate) !== null && _cofoData$Rate2 !== void 0 ? _cofoData$Rate2 : 0;
                            }
                            if (rates != 1)
                                calcBase_1 = new BigNumber(calcBase_1 / (1 - rates)).dp(2, 4).toNumber();
                        };
                        for (i = maxRank; i > rank_1; i--) {
                            _loop_1(i);
                        }
                        for (_i = 0, _a = rows.filter(function (r) { return r.Rank == rank_1; }); _i < _a.length; _i++) {
                            r = _a[_i];
                            cofoData = DiscountManager.getModulation(r, mode);
                            if (!r.IsRate)
                                calcBase_1 += (_cofoData$Value4 = cofoData === null || cofoData === void 0 ? void 0 : cofoData.Value) !== null && _cofoData$Value4 !== void 0 ? _cofoData$Value4 : 0;
                            else
                                calcBase_1 += getRowBase_1(row_1.DiscountClass).Base * ((_cofoData$Rate3 = cofoData === null || cofoData === void 0 ? void 0 : cofoData.Rate) !== null && _cofoData$Rate3 !== void 0 ? _cofoData$Rate3 : 0);
                        }
                    }
                    console.log("[setNet] calcBase", calcBase_1, 'getRowBase(row.DiscountClass)', getRowBase_1(row_1.DiscountClass), 'rate', 1 - calcBase_1 / getRowBase_1(row_1.DiscountClass).Base);
                    calcDiff = function (newValue) { var diff = getRowBase_1(row_1.DiscountClass).Base - (new BigNumber(newValue).dp(2, 4).toNumber() + new BigNumber(calcBase_1).dp(2, 4).toNumber()); return newValue + diff; };
                    if (!row_1.IsRate) {
                        newValue = getRowBase_1(row_1.DiscountClass).Base - calcBase_1;
                        fixedNewValue = calcDiff(newValue);
                        if (fixedNewValue < 0)
                            fixedNewValue = 0;
                        row_1[mode].Value = fixedNewValue;
                    }
                    else {
                        rate = 1 - calcBase_1 / getRowBase_1(row_1.DiscountClass).Base;
                        newValue = getRowBase_1(row_1.DiscountClass).Base * rate;
                        fixedNewValue = calcDiff(newValue);
                        row_1[mode].Value = fixedNewValue;
                        row_1.IsRate = false;
                    } // if (rate < 0)
                    //     rate = 0;
                    // row[mode].Rate = rate;
                }
                return [4 /*yield*/, DiscountManager.UpdateCascade(message, campaign)];
            case 6:
                messageCascade = _b.sent();
                console.log("[setNet] END", { message: message, base: base_1, mode: mode, value: value });
                return [3 /*break*/, 8];
            case 7:
                error_6 = _b.sent();
                console.log("[setNet] ERROR", error_6);
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/, { message: ToRefMessageSerializable(message), cascade: messageCascade }];
        }
    });
}); });
export var updateOffer = createAsyncThunk('messageEditor/updateOffer', function (message, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () { var _offer$ModelPropertie, state, offer, offerFormat, messageCascade, campaign, error_7; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0:
            _a.trys.push([0, 5, , 6]);
            state = thunkAPI.getState();
            offer = state.messageEditor.offer;
            offerFormat = state.messageEditor.offerFormat;
            messageCascade = null;
            campaign = state.messageEditor.getCampaign();
            if (!offerFormat) return [3 /*break*/, 2];
            return [4 /*yield*/, SetPriceOffer(message, campaign, offerFormat, 'Format')];
        case 1:
            messageCascade = _a.sent();
            _a.label = 2;
        case 2:
            if (!(offer !== null && offer !== void 0 && (_offer$ModelPropertie = offer.ModelProperties) !== null && _offer$ModelPropertie !== void 0 && _offer$ModelPropertie.Emplacement)) return [3 /*break*/, 4];
            return [4 /*yield*/, SetPriceOffer(message, campaign, offer, 'Placement', false)];
        case 3:
            messageCascade = _a.sent();
            _a.label = 4;
        case 4: return [2 /*return*/, { message: ToRefMessageSerializable(message), cascade: messageCascade }];
        case 5:
            error_7 = _a.sent();
            console.error(error_7);
            return [3 /*break*/, 6];
        case 6: return [2 /*return*/];
    }
}); }); });
export var setMessage = createAsyncThunk('messageEditor/message', function (message, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var _state$messageEditor$, _campaign, _newMsg$KPIs, _oldMsg$KPIs, _newMsg$KPIs2, _oldMsg$KPIs2, _state$messageEditor$2, _await$Client$searchV, _newMsg$Start, _newMsg$Start$getTime, _newMsg$End, _newMsg$End$getTime, _currency, _newMsg$KPIs3, _oldMsg$KPIs3, _newMsg$KPIs4, _oldMsg$KPIs4, _newMsg$KPIs5, _currencyCode, time7642, state, isInitializing, oldMsg_1, oldCampaigns, newMsg_1, isCreating, kpiManager, properties, campaign, agreements, currentAgreement, medias, digital, campRid, _filters$Campaign, filters, params, _a, _newMsg$Discounts, _b, _c, kpiManager_1, hasPerformances, oldPerfKPIs_1, newPerfKPIs_1, kpis, kpiCPM, _newPerfKPIs$kpiCPM$I, base, broadcastOptions, AdvCompany_ComOptions, AdvCompany_FinOptions, currencyOptions, clntDiscountRates, cloneMessageEditor, ag, agGlobal, globalAgreement, _d, offers, offer, offerFormat, isInitializingOffer, _oldMsg$ModelProperti, _newMsg$ModelProperti3, _newMsg$ModelProperti, _newMsg$ModelProperti2, preCreatedMsg, _newMsg$ModelProperti4, _newMsg$ModelProperti5, previousDiscountRates, _await$Client$searchV2, _support$_properties$, _properties, support, mediaFamilyOptions, placementCategoryOptions, currency, currencyCode, kpiCPMHasChanged, cascade, validationIssues, _time7642, error_8;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 75, , 76]);
                time7642 = new Date().getTime();
                ConsoleDebug('message param: ', message);
                state = thunkAPI.getState();
                ConsoleDebug('Mode: ', state.messageEditor.mode);
                ConsoleDebug('previous msg', state.messageEditor.data);
                isInitializing = Boolean(!state.messageEditor.data);
                oldMsg_1 = (_state$messageEditor$ = state.messageEditor.data) !== null && _state$messageEditor$ !== void 0 ? _state$messageEditor$ : new ref_Messages();
                oldCampaigns = state.messageEditor.campaign;
                newMsg_1 = clone(message);
                isCreating = newMsg_1 && !newMsg_1['@rid'];
                kpiManager = KPIsManagerCache.GetInstance(ref_Messages.name);
                return [4 /*yield*/, kpiManager.GetUserProperties()];
            case 1:
                properties = _e.sent();
                if (!isInitializing && newMsg_1 && !newMsg_1.DiscountMode) {
                    newMsg_1.DiscountMode = new ref_Messages().DiscountMode;
                }
                if (newMsg_1.Start && ['string', 'number'].includes(typeof newMsg_1.Start))
                    newMsg_1.Start = new Date(newMsg_1.Start);
                if (newMsg_1.End && ['string', 'number'].includes(typeof newMsg_1.End))
                    newMsg_1.End = new Date(newMsg_1.End);
                campaign = state.messageEditor.getCampaign();
                agreements = null;
                currentAgreement = undefined;
                return [4 /*yield*/, KPIsManagerCache.GetMedias()];
            case 2:
                medias = _e.sent();
                digital = medias.find(function (m) { return m.Code == "DIG"; });
                if (!isInitializing) return [3 /*break*/, 14];
                return [4 /*yield*/, DiscountManager.lockDiscountsLockedFromMAP(newMsg_1)];
            case 3:
                _e.sent();
                campRid = newMsg_1.Campaign;
                // on récupère les valeurs par défaut de la campagne sélectionnée dans les filtres
                if (!newMsg_1.Campaign && !newMsg_1['@rid']) {
                    filters = FilterStorage.GetLocalStorageFiltersObj();
                    if (((_filters$Campaign = filters.Campaign) === null || _filters$Campaign === void 0 ? void 0 : _filters$Campaign.length) == 1 && filters.Campaign[0])
                        campRid = filters.Campaign[0];
                }
                if (!campRid) return [3 /*break*/, 5];
                params = { "@rid": campRid, Source: "ADWONE" };
                _a = firstOrDefault;
                return [4 /*yield*/, Client.searchVertexTyped(ref_Campaigns, params)];
            case 4:
                campaign = _a.apply(void 0, [_e.sent()]);
                ConsoleDebug('set default campaign: ', params, campaign);
                _e.label = 5;
            case 5:
                if (campaign && isCreating)
                    kpiManager.SetMessagePropertiesFromCampaign(campaign, newMsg_1);
                if (!(digital && newMsg_1.Media == digital['@rid'])) return [3 /*break*/, 7];
                newMsg_1.IsGrossCPM = true;
                return [4 /*yield*/, kpiManager.InitializeCPM(newMsg_1, newMsg_1.KPIs)];
            case 6:
                _e.sent();
                _e.label = 7;
            case 7:
                if (!((newMsg_1.AdvertiserGroup || newMsg_1.Group) && newMsg_1.Currency && newMsg_1.Support && newMsg_1.Start)) return [3 /*break*/, 9];
                return [4 /*yield*/, getAvailableAgreements(newMsg_1)];
            case 8:
                agreements = _e.sent();
                return [3 /*break*/, 10];
            case 9:
                agreements = [];
                _e.label = 10;
            case 10:
                if (!newMsg_1.Agreement) return [3 /*break*/, 12];
                return [4 /*yield*/, Client.searchVertexTyped(ref_Agreements, { "@rid": newMsg_1.Agreement })];
            case 11:
                currentAgreement = (_e.sent())[0];
                return [3 /*break*/, 13];
            case 12:
                currentAgreement = null;
                _e.label = 13;
            case 13: return [3 /*break*/, 18];
            case 14:
                if (oldMsg_1.Support != newMsg_1.Support) {
                    OnSupportChange("ref_Messages", oldMsg_1, newMsg_1);
                    if ((newMsg_1.GlobalAgreement || newMsg_1.Agreement) && !newMsg_1.PricingLocked) {
                        newMsg_1.GlobalAgreement = null;
                        newMsg_1.Agreement = null;
                        newMsg_1.Discounts = (_newMsg$Discounts = newMsg_1.Discounts) === null || _newMsg$Discounts === void 0 ? void 0 : _newMsg$Discounts.filter(function (d) { return !d.Agreement; });
                    }
                }
                if (!(newMsg_1.Campaign != oldMsg_1.Campaign)) return [3 /*break*/, 18];
                if (!message.Campaign) return [3 /*break*/, 16];
                _c = firstOrDefault;
                return [4 /*yield*/, Client.searchVertexTyped(ref_Campaigns, { "@rid": message.Campaign, Source: "ADWONE" })];
            case 15:
                _b = _c.apply(void 0, [_e.sent()]);
                return [3 /*break*/, 17];
            case 16:
                _b = null;
                _e.label = 17;
            case 17:
                campaign = _b;
                kpiManager_1 = KPIsManagerCache.GetInstance(ref_Messages.name);
                kpiManager_1.SetMessagePropertiesFromCampaign(campaign, newMsg_1);
                _e.label = 18;
            case 18: return [4 /*yield*/, kpiManager.HasPerformances(newMsg_1.Media)];
            case 19:
                hasPerformances = _e.sent();
                oldPerfKPIs_1 = hasPerformances ? oldMsg_1.KPIs : oldCampaigns === null || oldCampaigns === void 0 ? void 0 : oldCampaigns.KPIs;
                newPerfKPIs_1 = hasPerformances ? newMsg_1.KPIs : (_campaign = campaign) === null || _campaign === void 0 ? void 0 : _campaign.KPIs;
                if (!newMsg_1.ModelProperties)
                    newMsg_1.ModelProperties = {};
                kpis = null;
                if (!(newMsg_1 !== null && newMsg_1 !== void 0 && newMsg_1.Media && newMsg_1.Media != oldMsg_1.Media)) return [3 /*break*/, 21];
                return [4 /*yield*/, kpiManager.GetLnkHasKPIs(newMsg_1.Media)];
            case 20:
                kpis = _e.sent();
                _e.label = 21;
            case 21:
                if (!(!isInitializing && state.messageEditor.kpis)) return [3 /*break*/, 24];
                if (!(newMsg_1.IsGrossCPM != oldMsg_1.IsGrossCPM)) return [3 /*break*/, 23];
                return [4 /*yield*/, kpiManager.InitializeCPM(newMsg_1, newPerfKPIs_1)];
            case 22:
                _e.sent();
                _e.label = 23;
            case 23:
                if (newMsg_1.IsGrossCPM) {
                    kpiCPM = state.messageEditor.kpis.find(function (kpi) { return kpi.KPI == newMsg_1.KpiCPM; });
                    if (newMsg_1.KpiCPM != oldMsg_1.KpiCPM || newMsg_1.KPIs["GrossCPM"] != oldMsg_1.KPIs["GrossCPM"] || newPerfKPIs_1[kpiCPM.Id] != oldPerfKPIs_1[kpiCPM.Id]) {
                        base = (_newPerfKPIs$kpiCPM$I = newPerfKPIs_1 === null || newPerfKPIs_1 === void 0 ? void 0 : newPerfKPIs_1[kpiCPM.Id]) !== null && _newPerfKPIs$kpiCPM$I !== void 0 ? _newPerfKPIs$kpiCPM$I : 0;
                        newMsg_1.KPIs.Gross = new BigNumber(base).multipliedBy(newMsg_1.KPIs["GrossCPM"]).dividedBy(1000).dp(2, 4).toNumber();
                    }
                }
                _e.label = 24;
            case 24:
                if (((_newMsg$KPIs = newMsg_1.KPIs) === null || _newMsg$KPIs === void 0 ? void 0 : _newMsg$KPIs.Gross) != ((_oldMsg$KPIs = oldMsg_1.KPIs) === null || _oldMsg$KPIs === void 0 ? void 0 : _oldMsg$KPIs.Gross) || ((_newMsg$KPIs2 = newMsg_1.KPIs) === null || _newMsg$KPIs2 === void 0 ? void 0 : _newMsg$KPIs2.GrossVal) != ((_oldMsg$KPIs2 = oldMsg_1.KPIs) === null || _oldMsg$KPIs2 === void 0 ? void 0 : _oldMsg$KPIs2.GrossVal)) {
                    ConsoleDebug('UpdateSPQ');
                    UpdateSPQ(newMsg_1);
                }
                broadcastOptions = undefined;
                AdvCompany_ComOptions = undefined;
                AdvCompany_FinOptions = undefined;
                currencyOptions = undefined;
                clntDiscountRates = undefined;
                cloneMessageEditor = clone(state.messageEditor);
                if (newMsg_1.Start && new Date(oldMsg_1.Start).getTime() != new Date(newMsg_1.Start).getTime()) {
                    cloneMessageEditor["AdvCompany_ComOptions"] = null;
                    cloneMessageEditor["AdvCompany_FinOptions"] = null;
                }
                if (!(newMsg_1.Campaign && kpiManager.SupportMode() == ref_Messages.name)) return [3 /*break*/, 29];
                ConsoleDebug('Update: BroadcastOptions, AdvCompanyOptions, CurrencyOptions');
                return [4 /*yield*/, UpdateBroadcastState(cloneMessageEditor, newMsg_1)];
            case 25:
                broadcastOptions = _e.sent();
                return [4 /*yield*/, UpdateAdvertisingCompany("AdvCompany_Com", cloneMessageEditor, newMsg_1)];
            case 26:
                AdvCompany_ComOptions = _e.sent();
                return [4 /*yield*/, UpdateAdvertisingCompany("AdvCompany_Fin", cloneMessageEditor, newMsg_1)];
            case 27:
                AdvCompany_FinOptions = _e.sent();
                return [4 /*yield*/, UpdateCurrencyState(cloneMessageEditor, newMsg_1)];
            case 28:
                currencyOptions = _e.sent();
                _e.label = 29;
            case 29:
                if (!newMsg_1.Support) {
                    newMsg_1.Currency = null;
                    newMsg_1.BroadcastArea = null;
                }
                if (!newMsg_1.BroadcastArea) {
                    newMsg_1.Format = null;
                    newMsg_1.Placement = null;
                } /** Agreement */
                if (!!isInitializing) return [3 /*break*/, 40];
                if (!(newMsg_1.Agreement != oldMsg_1.Agreement)) return [3 /*break*/, 31];
                return [4 /*yield*/, DiscountManager.initAgreement(newMsg_1, { refreshCurrent: true })];
            case 30:
                _e.sent();
                return [3 /*break*/, 33];
            case 31:
                if (!(newMsg_1.GlobalAgreement != oldMsg_1.GlobalAgreement)) return [3 /*break*/, 33];
                return [4 /*yield*/, DiscountManager.initGlobalAgreement(newMsg_1)];
            case 32:
                _e.sent();
                _e.label = 33;
            case 33:
                if (!(newMsg_1.Currency && newMsg_1.Start)) return [3 /*break*/, 35];
                if (!(oldMsg_1.BroadcastArea !== newMsg_1.BroadcastArea || oldMsg_1.Currency !== newMsg_1.Currency || oldMsg_1.Media != newMsg_1.Media || new Date(oldMsg_1.Start).getTime() != new Date(newMsg_1.Start).getTime())) return [3 /*break*/, 35];
                return [4 /*yield*/, DiscountManager.initGlobalAgreement(newMsg_1)];
            case 34:
                _e.sent();
                _e.label = 35;
            case 35:
                if (!((newMsg_1.AdvertiserGroup || newMsg_1.Group) && newMsg_1.Currency && newMsg_1.Support && newMsg_1.Start)) return [3 /*break*/, 39];
                if (!(oldMsg_1.BroadcastArea !== newMsg_1.BroadcastArea || oldMsg_1.Currency !== newMsg_1.Currency || oldMsg_1.Support != newMsg_1.Support || oldMsg_1.Media != newMsg_1.Media || oldMsg_1.Advertiser != newMsg_1.Advertiser || oldMsg_1.AdvertiserGroup != newMsg_1.AdvertiserGroup || oldMsg_1.Brand != newMsg_1.Brand || oldMsg_1.Product != newMsg_1.Product || oldMsg_1.Format != newMsg_1.Format || oldMsg_1.Placement != newMsg_1.Placement || new Date(oldMsg_1.Start).getTime() != new Date(newMsg_1.Start).getTime())) return [3 /*break*/, 38];
                ag = newMsg_1.Agreement;
                agGlobal = newMsg_1.GlobalAgreement;
                return [4 /*yield*/, DiscountManager.initAgreement(newMsg_1)];
            case 36:
                _e.sent(); //await DiscountManager.applyDiscounts(<ref_Messages><unknown>newMsg, { initAgreement: true });
                return [4 /*yield*/, getAvailableAgreements(newMsg_1)];
            case 37:
                agreements = _e.sent();
                if (ag != newMsg_1.Agreement || agGlobal != newMsg_1.GlobalAgreement) {
                    Notify(Trad("change_agreement_auto"), "warning");
                }
                _e.label = 38;
            case 38: return [3 /*break*/, 40];
            case 39:
                agreements = [];
                _e.label = 40;
            case 40:
                globalAgreement = null;
                if (!(newMsg_1.GlobalAgreement != ((_state$messageEditor$2 = state.messageEditor.globalAgreement) === null || _state$messageEditor$2 === void 0 ? void 0 : _state$messageEditor$2['@rid']))) return [3 /*break*/, 44];
                if (!newMsg_1.GlobalAgreement) return [3 /*break*/, 42];
                return [4 /*yield*/, Client.searchVertexTyped(ref_GlobalAgreements, { "@rid": newMsg_1.GlobalAgreement })];
            case 41:
                _d = (_await$Client$searchV = _e.sent()) === null || _await$Client$searchV === void 0 ? void 0 : _await$Client$searchV[0];
                return [3 /*break*/, 43];
            case 42:
                _d = null;
                _e.label = 43;
            case 43:
                globalAgreement = _d; /** Price Offer */
                _e.label = 44;
            case 44:
                offers = state.messageEditor.offers;
                offer = state.messageEditor.offer;
                offerFormat = state.messageEditor.offerFormat;
                isInitializingOffer = offers !== undefined;
                if (!(!isInitializingOffer || oldMsg_1.BroadcastArea !== newMsg_1.BroadcastArea || oldMsg_1.Media !== newMsg_1.Media || oldMsg_1.Support != newMsg_1.Support || oldMsg_1.Start != ((_newMsg$Start = newMsg_1.Start) === null || _newMsg$Start === void 0 ? void 0 : (_newMsg$Start$getTime = _newMsg$Start.getTime) === null || _newMsg$Start$getTime === void 0 ? void 0 : _newMsg$Start$getTime.call(_newMsg$Start)) || oldMsg_1.End != ((_newMsg$End = newMsg_1.End) === null || _newMsg$End === void 0 ? void 0 : (_newMsg$End$getTime = _newMsg$End.getTime) === null || _newMsg$End$getTime === void 0 ? void 0 : _newMsg$End$getTime.call(_newMsg$End)))) return [3 /*break*/, 49];
                return [4 /*yield*/, GetMessageOffers(newMsg_1)];
            case 45:
                offers = (_e.sent()).map(function (o) { return (__assign(__assign({}, o), { Start: o.Start ? new Date(o.Start).getTime() : undefined, End: o.End ? new Date(o.End).getTime() : undefined })); });
                offer = null;
                offerFormat = null;
                ConsoleDebug('load offers', offers, extractSub(clone(newMsg_1), ["BroadcastArea", "Media", "Start", "Support"]));
                if (isInitializing) {
                    if (newMsg_1.Format) {
                        offer = getDefaultFormatOffer({ format: newMsg_1.Format, couleur: newMsg_1 === null || newMsg_1 === void 0 ? void 0 : newMsg_1.ModelProperties["Couleur"], offers: offers });
                        offerFormat = offer;
                    }
                    if (newMsg_1.Placement)
                        offer = getFormatOffer({ format: newMsg_1.Format, couleur: newMsg_1 === null || newMsg_1 === void 0 ? void 0 : newMsg_1.ModelProperties["Couleur"], placement: newMsg_1.Placement, offers: offers });
                }
                if (!(isInitializingOffer && !isInitializing)) return [3 /*break*/, 49];
                if (!newMsg_1.Format) return [3 /*break*/, 47];
                offer = getDefaultFormatOffer({ format: newMsg_1.Format, couleur: newMsg_1 === null || newMsg_1 === void 0 ? void 0 : newMsg_1.ModelProperties["Couleur"], offers: offers });
                offerFormat = offer;
                return [4 /*yield*/, SetPriceOffer(newMsg_1, campaign, offer, "Format")];
            case 46:
                _e.sent();
                _e.label = 47;
            case 47:
                if (!newMsg_1.Placement) return [3 /*break*/, 49];
                offer = getFormatOffer({ format: newMsg_1.Format, couleur: newMsg_1 === null || newMsg_1 === void 0 ? void 0 : newMsg_1.ModelProperties["Couleur"], placement: newMsg_1.Placement, offers: offers });
                return [4 /*yield*/, SetPriceOffer(newMsg_1, campaign, offer, "Placement", false)];
            case 48:
                _e.sent();
                _e.label = 49;
            case 49:
                if (!!isInitializing) return [3 /*break*/, 61];
                if (!(oldMsg_1.Format != newMsg_1.Format || oldMsg_1.ModelProperties["Couleur"] != newMsg_1.ModelProperties["Couleur"])) return [3 /*break*/, 54];
                offer = getDefaultFormatOffer({ format: newMsg_1.Format, couleur: newMsg_1 === null || newMsg_1 === void 0 ? void 0 : newMsg_1.ModelProperties["Couleur"], offers: offers });
                offerFormat = offer;
                return [4 /*yield*/, SetPriceOffer(newMsg_1, campaign, offer, "Format", !newMsg_1.PricingLocked)];
            case 50:
                _e.sent();
                if (!newMsg_1.Placement) return [3 /*break*/, 52];
                offer = getFormatOffer({ format: newMsg_1.Format, couleur: newMsg_1 === null || newMsg_1 === void 0 ? void 0 : newMsg_1.ModelProperties["Couleur"], placement: newMsg_1.Placement, offers: offers });
                return [4 /*yield*/, SetPriceOffer(newMsg_1, campaign, offer, "Placement", false)];
            case 51:
                _e.sent();
                _e.label = 52;
            case 52:
                if ((_newMsg$ModelProperti = newMsg_1.ModelProperties) !== null && _newMsg$ModelProperti !== void 0 && _newMsg$ModelProperti.BroadcastPlacement) {
                    offer = getFormatOffer({ format: newMsg_1.Format, couleur: newMsg_1 === null || newMsg_1 === void 0 ? void 0 : newMsg_1.ModelProperties["Couleur"], placement: (_newMsg$ModelProperti2 = newMsg_1.ModelProperties) === null || _newMsg$ModelProperti2 === void 0 ? void 0 : _newMsg$ModelProperti2.BroadcastPlacement, offers: offers });
                    SetBroadcastPriceOffer(newMsg_1, offer);
                }
                return [4 /*yield*/, Client.preCreateVertex(ref_Messages, [newMsg_1])];
            case 53:
                preCreatedMsg = (_e.sent())[0];
                newMsg_1.KPIs = __assign(__assign({}, newMsg_1.KPIs), (preCreatedMsg === null || preCreatedMsg === void 0 ? void 0 : preCreatedMsg.KPIs));
                _e.label = 54;
            case 54:
                if (!(oldMsg_1.Placement != newMsg_1.Placement)) return [3 /*break*/, 56];
                offer = getFormatOffer({ format: newMsg_1.Format, couleur: newMsg_1 === null || newMsg_1 === void 0 ? void 0 : newMsg_1.ModelProperties["Couleur"], placement: newMsg_1.Placement, offers: offers });
                return [4 /*yield*/, SetPriceOffer(newMsg_1, campaign, offer, "Placement")];
            case 55:
                _e.sent(); //SetPriceOffer(newMsg, getPlacementOffer({ "@rid": message.Placement }, offers), "Placement");
                _e.label = 56;
            case 56:
                if (!(((_oldMsg$ModelProperti = oldMsg_1.ModelProperties) === null || _oldMsg$ModelProperti === void 0 ? void 0 : _oldMsg$ModelProperti.BroadcastPlacement) != ((_newMsg$ModelProperti3 = newMsg_1.ModelProperties) === null || _newMsg$ModelProperti3 === void 0 ? void 0 : _newMsg$ModelProperti3.BroadcastPlacement))) return [3 /*break*/, 59];
                if (!!((_newMsg$ModelProperti4 = newMsg_1.ModelProperties) !== null && _newMsg$ModelProperti4 !== void 0 && _newMsg$ModelProperti4.BroadcastPlacement)) return [3 /*break*/, 57];
                newMsg_1.KPIs.BroadcastGross = 0;
                return [3 /*break*/, 59];
            case 57: return [4 /*yield*/, SetBroadcastPriceOffer(newMsg_1, getFormatOffer({ format: newMsg_1.Format, couleur: newMsg_1 === null || newMsg_1 === void 0 ? void 0 : newMsg_1.ModelProperties["Couleur"], placement: (_newMsg$ModelProperti5 = newMsg_1.ModelProperties) === null || _newMsg$ModelProperti5 === void 0 ? void 0 : _newMsg$ModelProperti5.BroadcastPlacement, offers: offers }))];
            case 58:
                _e.sent();
                _e.label = 59;
            case 59:
                if (!isCreating) return [3 /*break*/, 61];
                return [4 /*yield*/, SetPerformancesKPIFromOJD(newMsg_1, oldMsg_1)];
            case 60:
                _e.sent();
                _e.label = 61;
            case 61:
                if (!((newMsg_1.AdvertiserGroup || newMsg_1.Advertiser) && newMsg_1.BroadcastArea && newMsg_1.Media)) return [3 /*break*/, 64];
                if (!(isInitializing || oldMsg_1.BroadcastArea !== newMsg_1.BroadcastArea || oldMsg_1.Media != newMsg_1.Media || oldMsg_1.Advertiser != newMsg_1.Advertiser || oldMsg_1.AdvertiserGroup != newMsg_1.AdvertiserGroup)) return [3 /*break*/, 64];
                previousDiscountRates = clone(state.messageEditor.clntDiscountRates);
                return [4 /*yield*/, getAvailableDiscountRates(newMsg_1)];
            case 62:
                clntDiscountRates = _e.sent();
                console.log("Rates", clntDiscountRates, isCreating);
                if (!(!isInitializing || state.messageEditor.mode == eDialogMode.duplicate)) return [3 /*break*/, 64];
                return [4 /*yield*/, InitDiscountRates(newMsg_1, clntDiscountRates, previousDiscountRates)];
            case 63:
                _e.sent();
                _e.label = 64;
            case 64:
                if (!(properties.some(function (p) { return p.name == "ModelProperties.SupportDetails"; }) && oldMsg_1.Support !== newMsg_1.Support && !isInitializing)) return [3 /*break*/, 66];
                _properties = ["Description", "ClntData.ModelProperties.Description"];
                return [4 /*yield*/, Client.searchVertexTyped(ref_Supports, { "@rid": newMsg_1.Support, properties: _properties })];
            case 65:
                support = (_await$Client$searchV2 = _e.sent()) === null || _await$Client$searchV2 === void 0 ? void 0 : _await$Client$searchV2[0];
                newMsg_1.ModelProperties["SupportDetails"] = (_support$_properties$ = support === null || support === void 0 ? void 0 : support[_properties[1]]) !== null && _support$_properties$ !== void 0 ? _support$_properties$ : support === null || support === void 0 ? void 0 : support[_properties[0]];
                _e.label = 66;
            case 66:
                mediaFamilyOptions = null;
                if (!(properties.some(function (p) { return p.name == "ModelProperties.MediaFamily"; }) && oldMsg_1.Support !== newMsg_1.Support)) return [3 /*break*/, 68];
                ConsoleDebug('Update: MediaFamilyOptions');
                return [4 /*yield*/, SetGroup(newMsg_1, newMsg_1.Support, eGroupCategories.MediaFamily, "MediaFamily", "MediaFamilyOther", !isInitializing)];
            case 67:
                mediaFamilyOptions = _e.sent();
                _e.label = 68;
            case 68:
                placementCategoryOptions = null;
                if (!(oldMsg_1.Placement !== newMsg_1.Placement)) return [3 /*break*/, 70];
                ConsoleDebug('Update: PlacementCategoryOptions');
                return [4 /*yield*/, SetGroup(newMsg_1, newMsg_1.Placement, eGroupCategories.PlacementCategory, "PlacementCategory", "PlacementCategoryOther", !isInitializing)];
            case 69:
                placementCategoryOptions = _e.sent();
                _e.label = 70;
            case 70:
                if (!newMsg_1.Discounts)
                    newMsg_1.Discounts = [];
                currency = state.messageEditor.currency;
                currencyCode = state.messageEditor.currencyCode;
                if (!(newMsg_1.Currency != ((_currency = currency) === null || _currency === void 0 ? void 0 : _currency['@rid']))) return [3 /*break*/, 72];
                currency = undefined;
                currencyCode = "";
                if (!newMsg_1.Currency) return [3 /*break*/, 72];
                return [4 /*yield*/, Client.searchVertexTyped(ref_Currencies, { "@rid": newMsg_1.Currency })];
            case 71:
                currency = (_e.sent())[0];
                currencyCode = currency.Code;
                _e.label = 72;
            case 72:
                kpiCPMHasChanged = function () { var _oldMsg$Discounts, _newMsg$Discounts2; var oldKpis = (_oldMsg$Discounts = oldMsg_1.Discounts) === null || _oldMsg$Discounts === void 0 ? void 0 : _oldMsg$Discounts.filter(function (d) { return d.IsCPM; }).map(function (d) { return oldPerfKPIs_1[d.KpiCPM]; }); var newKpis = newMsg_1 === null || newMsg_1 === void 0 ? void 0 : (_newMsg$Discounts2 = newMsg_1.Discounts) === null || _newMsg$Discounts2 === void 0 ? void 0 : _newMsg$Discounts2.filter(function (d) { return d.IsCPM; }).map(function (d) { return newPerfKPIs_1[d.KpiCPM]; }); return !compareObjects(oldKpis, newKpis); };
                cascade = undefined;
                if (!(((_newMsg$KPIs3 = newMsg_1.KPIs) === null || _newMsg$KPIs3 === void 0 ? void 0 : _newMsg$KPIs3.Gross) != ((_oldMsg$KPIs3 = oldMsg_1.KPIs) === null || _oldMsg$KPIs3 === void 0 ? void 0 : _oldMsg$KPIs3.Gross) || !compareObjects(newMsg_1.Discounts, oldMsg_1.Discounts) || kpiCPMHasChanged())) return [3 /*break*/, 74];
                return [4 /*yield*/, DiscountManager.UpdateCascade(newMsg_1, campaign)];
            case 73:
                cascade = _e.sent(); // if net has changed, because of a change in agreement/GlobalAgreement/format/placement/start we notify the user
                _e.label = 74;
            case 74:
                if (!isInitializing && ((_newMsg$KPIs4 = newMsg_1.KPIs) === null || _newMsg$KPIs4 === void 0 ? void 0 : _newMsg$KPIs4.NetCO) != ((_oldMsg$KPIs4 = oldMsg_1.KPIs) === null || _oldMsg$KPIs4 === void 0 ? void 0 : _oldMsg$KPIs4.NetCO) && (newMsg_1.Agreement != oldMsg_1.Agreement || newMsg_1.GlobalAgreement != oldMsg_1.GlobalAgreement || newMsg_1.Format != oldMsg_1.Format || newMsg_1.Placement != oldMsg_1.Placement || new Date(oldMsg_1.Start).getTime() != new Date(newMsg_1.Start).getTime()))
                    Notify("".concat(Trad("net_has_changed"), ": ").concat(GetKPITemplate(eKPIType.Price)((_newMsg$KPIs5 = newMsg_1.KPIs) === null || _newMsg$KPIs5 === void 0 ? void 0 : _newMsg$KPIs5.NetCO), " ").concat((_currencyCode = currencyCode) !== null && _currencyCode !== void 0 ? _currencyCode : ''), "warning");
                validationIssues = SetValidationIssues(campaign, newMsg_1, state.messageEditor.validationIssues);
                _time7642 = new Date().getTime();
                ConsoleDebug("SetMessage Elapsed ".concat(_time7642 - time7642, "ms"), newMsg_1, validationIssues);
                return [2 /*return*/, { cascade: cascade, message: ToRefMessageSerializable(newMsg_1), campaign: campaign ? ToRefCampaignSerializable(campaign) : null, validationIssues: validationIssues, offers: offers, offer: offer, offerFormat: offerFormat, currency: currency, currencyCode: currencyCode, currencyOptions: currencyOptions, broadcastOptions: broadcastOptions, mediaFamilyOptions: mediaFamilyOptions, placementCategoryOptions: placementCategoryOptions, AdvCompany_ComOptions: AdvCompany_ComOptions, AdvCompany_FinOptions: AdvCompany_FinOptions, agreements: agreements, globalAgreement: globalAgreement, kpis: kpis, currentAgreement: currentAgreement, clntDiscountRates: clntDiscountRates }];
            case 75:
                error_8 = _e.sent();
                console.error(error_8);
                Notify(Trad('error'), 'error');
                Client.log({ Category: 'ERROR', Action: 'setMessage', Informations: SerializeError(error_8) });
                return [3 /*break*/, 76];
            case 76: return [2 /*return*/];
        }
    });
}); });
export function SetValidationIssues(campaign, message, oldValidationIssues) { var _GetUser, _GetUser$customer, _GetUser2, _GetUser2$customer, _message$KPIs; var issues = []; var mandatory = ["Campaign", "Start", "Format"]; if (((_GetUser = GetUser()) === null || _GetUser === void 0 ? void 0 : (_GetUser$customer = _GetUser.customer) === null || _GetUser$customer === void 0 ? void 0 : _GetUser$customer.AdvertiserMode) == ref_Messages.name) {
    if (RightManager.hasRight(ref_AdvertiserGroups.name, eRights.read))
        mandatory.push("AdvertiserGroup");
    else
        mandatory.push("Advertiser");
} if (((_GetUser2 = GetUser()) === null || _GetUser2 === void 0 ? void 0 : (_GetUser2$customer = _GetUser2.customer) === null || _GetUser2$customer === void 0 ? void 0 : _GetUser2$customer.SupportMode) == ref_Messages.name)
    mandatory = __spreadArray(__spreadArray([], mandatory, true), ["Support", "Currency", "BroadcastArea"], false); mandatory.forEach(function (p) { if (!message[p])
    issues.push(p); }); if (campaign !== null && campaign !== void 0 && campaign.KPIs[eKPI.Pagination] && ((_message$KPIs = message.KPIs) === null || _message$KPIs === void 0 ? void 0 : _message$KPIs[eKPI.Position]) > campaign.KPIs[eKPI.Pagination]) {
    if (!oldValidationIssues.includes(eKPI.Position))
        Notify(Trad('position_is_greater_than_pagination'), 'warning');
    issues.push(eKPI.Position);
} return issues; }
export var messageEditorSlice = createSlice({ name: 'messageEditor', initialState: clone(initialState), extraReducers: function (builder) {
        builder.addCase(setMessage.pending, function (state, action) { state.lockNext = true; });
        var fulfilledCallback = function (state, action) { var _action$payload, _action$payload2, _action$payload3, _action$payload4, _action$payload5, _action$payload6, _action$payload7, _action$payload8, _action$payload9, _action$payload10, _action$payload11, _action$payload12, _action$payload13, _action$payload14, _action$payload15, _action$payload16, _action$payload17, _action$payload18, _action$payload19, _action$payload20, _action$payload21; if ((_action$payload = action.payload) !== null && _action$payload !== void 0 && _action$payload.message)
            state.data = action.payload.message; if ((_action$payload2 = action.payload) !== null && _action$payload2 !== void 0 && _action$payload2.validationIssues)
            state.validationIssues = action.payload.validationIssues; if ((_action$payload3 = action.payload) !== null && _action$payload3 !== void 0 && _action$payload3.currencyCode)
            state.currencyCode = action.payload.currencyCode; if ((_action$payload4 = action.payload) !== null && _action$payload4 !== void 0 && _action$payload4.currency)
            state.currency = action.payload.currency; if (((_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.campaign) !== undefined)
            state.campaign = action.payload.campaign; if ((_action$payload6 = action.payload) !== null && _action$payload6 !== void 0 && _action$payload6.offers)
            state.offers = action.payload.offers; if (((_action$payload7 = action.payload) === null || _action$payload7 === void 0 ? void 0 : _action$payload7.offer) !== undefined)
            state.offer = action.payload.offer; if (((_action$payload8 = action.payload) === null || _action$payload8 === void 0 ? void 0 : _action$payload8.offerFormat) !== undefined)
            state.offerFormat = action.payload.offerFormat; if (action !== null && action !== void 0 && (_action$payload9 = action.payload) !== null && _action$payload9 !== void 0 && _action$payload9.currencyOptions)
            state.currencyOptions = action.payload.currencyOptions; if (action !== null && action !== void 0 && (_action$payload10 = action.payload) !== null && _action$payload10 !== void 0 && _action$payload10.broadcastOptions)
            state.broadcastOptions = action.payload.broadcastOptions; if (action !== null && action !== void 0 && (_action$payload11 = action.payload) !== null && _action$payload11 !== void 0 && _action$payload11.mediaFamilyOptions)
            state.groups.MediaFamily = action.payload.mediaFamilyOptions; if (action !== null && action !== void 0 && (_action$payload12 = action.payload) !== null && _action$payload12 !== void 0 && _action$payload12.agencyOptions)
            state.agencyOptions = action.payload.agencyOptions; if (action !== null && action !== void 0 && (_action$payload13 = action.payload) !== null && _action$payload13 !== void 0 && _action$payload13.placementCategoryOptions)
            state.groups.PlacementCategory = action.payload.placementCategoryOptions; if (action !== null && action !== void 0 && (_action$payload14 = action.payload) !== null && _action$payload14 !== void 0 && _action$payload14.AdvCompany_ComOptions)
            state.AdvCompany_ComOptions = action.payload.AdvCompany_ComOptions; if (action !== null && action !== void 0 && (_action$payload15 = action.payload) !== null && _action$payload15 !== void 0 && _action$payload15.AdvCompany_FinOptions)
            state.AdvCompany_FinOptions = action.payload.AdvCompany_FinOptions; if (action !== null && action !== void 0 && (_action$payload16 = action.payload) !== null && _action$payload16 !== void 0 && _action$payload16.agreements)
            state.agreements = action.payload.agreements; if (action !== null && action !== void 0 && (_action$payload17 = action.payload) !== null && _action$payload17 !== void 0 && _action$payload17.globalAgreement)
            state.globalAgreement = action.payload.globalAgreement; if (action !== null && action !== void 0 && (_action$payload18 = action.payload) !== null && _action$payload18 !== void 0 && _action$payload18.kpis)
            state.kpis = action.payload.kpis; if ((action === null || action === void 0 ? void 0 : (_action$payload19 = action.payload) === null || _action$payload19 === void 0 ? void 0 : _action$payload19.currentAgreement) !== undefined)
            state.currentAgreement = action.payload.currentAgreement; if ((action === null || action === void 0 ? void 0 : (_action$payload20 = action.payload) === null || _action$payload20 === void 0 ? void 0 : _action$payload20.cascade) !== undefined)
            state.cascade = action.payload.cascade; if (((_action$payload21 = action.payload) === null || _action$payload21 === void 0 ? void 0 : _action$payload21.clntDiscountRates) !== undefined)
            state.clntDiscountRates = action.payload.clntDiscountRates; };
        builder.addCase(setMessage.fulfilled, function (state, action) { fulfilledCallback(state, action); state.lockNext = false; });
        builder.addCase(updateOffer.fulfilled, function (state, action) { fulfilledCallback(state, action); });
        builder.addCase(setNet.fulfilled, fulfilledCallback);
        builder.addCase(initMessageAgencyOptions.fulfilled, fulfilledCallback);
        builder.addCase(initMessageCurrencyOptions.fulfilled, fulfilledCallback);
        builder.addCase(initMessageBroadcastOptions.fulfilled, fulfilledCallback);
        builder.addCase(initAdvComOptions.fulfilled, fulfilledCallback);
        builder.addCase(initAdvFinOptions.fulfilled, fulfilledCallback);
    }, reducers: { lockNext: function (state, action) { state.lockNext = true; }, unlockNext: function (state, action) { state.lockNext = false; }, toggleRepeat: function (state, action) { state.repeat = !state.repeat; }, disableRepeat: function (state, action) { state.repeat = false; }, setMessageMode: function (state, action) { state.mode = action.payload; }, setStart: function (state, action) { var start = action.payload.start; state.data.Start = start === null || start === void 0 ? void 0 : start.toString(); if (action.payload.force) { } }, setMessagePublications: function (state, action) { state.publications = action.payload ? duplicate(action.payload) : undefined; }, setCurrentNetType: function (state, action) { state.currentNetType = action.payload; }, setMessageSync: function (state, action) { if (!action.payload) {
            var cloneValues = clone(initialState);
            Object.entries(cloneValues).forEach(function (_ref2) { var key = _ref2[0], value = _ref2[1]; state[key] = value; });
            return;
        } var newMsg = __assign(__assign({}, duplicate(action.payload)), { Start: action.payload.Start ? new Date(action.payload.Start).getTime() : undefined, End: action.payload.End ? new Date(action.payload.End).getTime() : undefined }); if (!newMsg.Discounts)
            newMsg.Discounts = []; if (!newMsg.ModelProperties)
            newMsg.ModelProperties = {}; state.data = newMsg; }, clearMessageEditor: function (state, action) { state.currencyOptions = undefined; state.broadcastOptions = undefined; state.AdvCompany_ComOptions = undefined; state.AdvCompany_FinOptions = undefined; } // setOffers: (state, action: PayloadAction<ref_Offers[]>) => {
        //     state.offers = action?.payload.map(o => ({
        //         ...duplicate(o),
        //         Start: o.Start ? new Date(o.Start).getTime() : undefined,
        //         End: o.End ? new Date(o.End).getTime() : undefined,
        //     }))
        // }
    } });
export function GetDefaultAdvertisingCompany(support, broadcastArea) {
    return __awaiter(this, void 0, void 0, function () {
        var _user$customer, options_1, tempParams, user, newOptions, subAgencies, _a, defaultValue_1, setValue_1, setDefaultValue, error_9;
        var _this = this;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 6, , 7]);
                    options_1 = undefined;
                    ConsoleDebug('UpdateAdvertisingCompany');
                    tempParams = duplicate({ Active: true, out: [support], properties: ["in", "@class", "in.Name as Name", "in.Active as Active", "in.@rid as outRid", "Default", "Roles", "DefaultBroadcastArea"] // ...params
                    });
                    user = JSON.parse(localStorage.getItem("user"));
                    return [4 /*yield*/, Client.searchVertexTyped(lnk_AdvertisingCompanySupport, tempParams)];
                case 1:
                    newOptions = (_b.sent()).filter(function (e) { return e.Active; });
                    if (!((user === null || user === void 0 ? void 0 : (_user$customer = user.customer) === null || _user$customer === void 0 ? void 0 : _user$customer.Authorization) == "MediaManager")) return [3 /*break*/, 3];
                    return [4 /*yield*/, Client.searchVertexTyped(ref_SubAgencies)];
                case 2:
                    _a = _b.sent();
                    return [3 /*break*/, 4];
                case 3:
                    _a = [];
                    _b.label = 4;
                case 4:
                    subAgencies = _a;
                    options_1 = __spreadArray(__spreadArray([], newOptions, true), subAgencies, true).sort(function (a, b) { return a.Name.localeCompare(b.Name); });
                    options_1 = distinct(options_1, function (_value) { var _value$outRid; return (_value$outRid = _value === null || _value === void 0 ? void 0 : _value['outRid']) !== null && _value$outRid !== void 0 ? _value$outRid : _value === null || _value === void 0 ? void 0 : _value['@rid']; });
                    defaultValue_1 = null;
                    setValue_1 = function (value) { if (!value) {
                        defaultValue_1 = null;
                        return;
                    } var newValue; if (value["@class"] == lnk_AdvertisingCompanySupport.name)
                        newValue = value.outRid;
                    else
                        newValue = value["@rid"]; defaultValue_1 = newValue; };
                    setDefaultValue = function () { return __awaiter(_this, void 0, void 0, function () { var _options2, _options3, _options, defaultVal, found; return __generator(this, function (_a) {
                        if (((_options2 = options_1) === null || _options2 === void 0 ? void 0 : _options2.length) === 1)
                            return [2 /*return*/, setValue_1(options_1[0])];
                        _options = (_options3 = options_1) === null || _options3 === void 0 ? void 0 : _options3.filter(function (o) { return o["@class"] == lnk_AdvertisingCompanySupport.name; });
                        if (!defaultVal)
                            defaultVal = _options === null || _options === void 0 ? void 0 : _options.find(function (v) { return v.Default; });
                        if (!defaultVal && broadcastArea) {
                            found = _options === null || _options === void 0 ? void 0 : _options.find(function (e) { return e.DefaultBroadcastArea == broadcastArea; });
                            if (found) {
                                defaultValue_1 = found.in;
                                return [2 /*return*/];
                            }
                        }
                        setValue_1(defaultVal);
                        return [2 /*return*/];
                    }); }); };
                    return [4 /*yield*/, setDefaultValue()];
                case 5:
                    _b.sent();
                    return [2 /*return*/, { options: options_1.map(function (o) { var _o$outRid; return { '@rid': (_o$outRid = o['outRid']) !== null && _o$outRid !== void 0 ? _o$outRid : o['@rid'], Name: o['Name'] }; }), defaultValue: defaultValue_1 }];
                case 6:
                    error_9 = _b.sent();
                    console.log("error UpdateAdvertisingCompany", error_9);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    });
}
export function GetDefaultBroadcastAreas(support) {
    return __awaiter(this, void 0, void 0, function () {
        var broadcastAreaOptions, dispatchChanged, lnkAdv, adv, broadcastAreas, setDefault;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    broadcastAreaOptions = undefined;
                    ConsoleDebug('UpdateBroadcastState');
                    dispatchChanged = function (broadcastArea) { return { broadcastAreaOptions: broadcastAreaOptions, broadcastAreaDefault: broadcastArea === null || broadcastArea === void 0 ? void 0 : broadcastArea["@rid"] }; };
                    return [4 /*yield*/, Client.searchVertexTyped(lnk_AdvertisingCompanySupport, { "out": support })];
                case 1:
                    lnkAdv = _a.sent();
                    adv = distinct(lnkAdv.map(function (l) { return l.in; }), function (e) { return e; });
                    return [4 /*yield*/, Client.searchVertexTyped(lnk_HasBroadcastArea, { "in": __spreadArray([support], adv, true) })];
                case 2:
                    broadcastAreas = _a.sent();
                    setDefault = function () { return __awaiter(_this, void 0, void 0, function () {
                        var advDefault, _broadcastAreas$find, found_1, broadcastAdvDefault_1, defaultElement;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, Client.searchVertexTyped(ref_BroadcastAreas, { Active: true, "@rid": distinct(broadcastAreas.map(function (l) { return l.out; }), function (e) { return e; }), properties: ["@rid", "Name"] })];
                                case 1:
                                    broadcastAreaOptions = _a.sent();
                                    if (broadcastAreaOptions.length == 1) {
                                        return [2 /*return*/, dispatchChanged(broadcastAreaOptions[0])];
                                    } // const selectedElement = broadcastAreaOptions?.find(o => o["@rid"] == message.BroadcastArea);
                                    advDefault = distinct(lnkAdv.map(function (lnk) { return lnk.in; }), function (e) { return e; });
                                    if (advDefault.length > 1)
                                        advDefault = distinct(lnkAdv.filter(function (lnk) { return lnk.Default; }).map(function (lnk) { return lnk.in; }), function (e) { return e; });
                                    if (advDefault.length) {
                                        found_1 = lnkAdv.filter(function (lnk) { return advDefault.includes(lnk.in) && lnk.DefaultBroadcastArea && (advDefault.length == 1 || lnk.Default); }).map(function (lnk) { return lnk.DefaultBroadcastArea; });
                                        if (found_1.length)
                                            return [2 /*return*/, dispatchChanged(broadcastAreaOptions.find(function (o) { return found_1.includes(o["@rid"]); }))]; // recherche de la zone de diff par défaut de la première régie trouvée
                                        broadcastAdvDefault_1 = (_broadcastAreas$find = broadcastAreas.find(function (lnkB) { return advDefault.includes(lnkB.in) && lnkB.Default; })) === null || _broadcastAreas$find === void 0 ? void 0 : _broadcastAreas$find.out;
                                        if (broadcastAdvDefault_1)
                                            return [2 /*return*/, dispatchChanged(broadcastAreaOptions.find(function (o) { return o["@rid"] == broadcastAdvDefault_1; }))];
                                    } // recherche des zones de diff par défaut du support
                                    defaultElement = broadcastAreaOptions.find(function (o) { return broadcastAreas.some(function (lnk) { return lnk.out == o["@rid"] && lnk.Default; }); });
                                    return [2 /*return*/, dispatchChanged(defaultElement)];
                            }
                        });
                    }); };
                    return [2 /*return*/, setDefault()];
            }
        });
    });
}
var financialRid = undefined;
var commercialRid = undefined;
function UpdateAdvertisingCompany(property, messageEditor, message) {
    return __awaiter(this, void 0, void 0, function () {
        var _await$Client$get, _await$Client$get$dat, _await$Client$get$dat2, _await$Client$get$dat3, _await$Client$get2, _await$Client$get2$da, _await$Client$get2$da2, _await$Client$get2$da3, _messageEditor$data, currentOptions, options_2, _a, Support, ApplyComBarter, KPIs, _user$customer2, setValue_2, params, barterCompanies, _b, tempParams, user, newOptions, _c, subAgencies, _d, setDefaultValue, _options7, defaultValue, missingValue, error_10;
        var _this = this;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _e.trys.push([0, 21, , 22]);
                    currentOptions = messageEditor["".concat(property, "Options")];
                    options_2 = undefined;
                    _a = (_messageEditor$data = messageEditor.data) !== null && _messageEditor$data !== void 0 ? _messageEditor$data : {}, Support = _a.Support, ApplyComBarter = _a.ApplyComBarter, KPIs = _a.KPIs;
                    if (!(currentOptions == undefined || Support !== message.Support || ApplyComBarter != message.ApplyComBarter || !(KPIs !== null && KPIs !== void 0 && KPIs.Barter) && message.KPIs.Barter || KPIs !== null && KPIs !== void 0 && KPIs.Barter && !message.KPIs.Barter)) return [3 /*break*/, 20];
                    ConsoleDebug('UpdateAdvertisingCompany');
                    setValue_2 = function (value) { if (!value) {
                        message[property] = null;
                        return;
                    } var newValue; if (value["@class"] == lnk_AdvertisingCompanySupport.name)
                        newValue = value.outRid;
                    else
                        newValue = value["@rid"]; message[property] = newValue; };
                    params = undefined;
                    barterCompanies = [];
                    _b = property;
                    switch (_b) {
                        case "AdvCompany_Com": return [3 /*break*/, 1];
                        case "AdvCompany_Fin": return [3 /*break*/, 4];
                    }
                    return [3 /*break*/, 7];
                case 1:
                    if (!!commercialRid) return [3 /*break*/, 3];
                    return [4 /*yield*/, Client.get(ref_AdvertisingCompanyRole, { Name: "Commercial" })];
                case 2:
                    commercialRid = (_await$Client$get = _e.sent()) === null || _await$Client$get === void 0 ? void 0 : (_await$Client$get$dat = _await$Client$get.data) === null || _await$Client$get$dat === void 0 ? void 0 : (_await$Client$get$dat2 = _await$Client$get$dat.results) === null || _await$Client$get$dat2 === void 0 ? void 0 : (_await$Client$get$dat3 = _await$Client$get$dat2[0]) === null || _await$Client$get$dat3 === void 0 ? void 0 : _await$Client$get$dat3["@rid"];
                    _e.label = 3;
                case 3:
                    params = { Roles: commercialRid };
                    return [3 /*break*/, 8];
                case 4:
                    if (!!financialRid) return [3 /*break*/, 6];
                    return [4 /*yield*/, Client.get(ref_AdvertisingCompanyRole, { Name: "Financial" })];
                case 5:
                    financialRid = (_await$Client$get2 = _e.sent()) === null || _await$Client$get2 === void 0 ? void 0 : (_await$Client$get2$da = _await$Client$get2.data) === null || _await$Client$get2$da === void 0 ? void 0 : (_await$Client$get2$da2 = _await$Client$get2$da.results) === null || _await$Client$get2$da2 === void 0 ? void 0 : (_await$Client$get2$da3 = _await$Client$get2$da2[0]) === null || _await$Client$get2$da3 === void 0 ? void 0 : _await$Client$get2$da3["@rid"];
                    _e.label = 6;
                case 6:
                    params = { Roles: financialRid };
                    return [3 /*break*/, 8];
                case 7: return [3 /*break*/, 8];
                case 8:
                    if (!((message.KPIs.Barter || message.SmartBarter) && (property == "AdvCompany_Fin" || message.ApplyComBarter))) return [3 /*break*/, 10];
                    return [4 /*yield*/, Client.searchVertexTyped(ref_AdvertisingCompanies, { "IsBarter": true })];
                case 9:
                    barterCompanies = _e.sent();
                    _e.label = 10;
                case 10:
                    tempParams = message.Support && duplicate(__assign({ Active: true, out: [message.Support].filter(function (e) { return e; }), properties: ["in", "Start", "End", "@class", "in.Name as Name", "in.Start as ValidStart", "in.End as ValidEnd", "in.@rid as outRid", "Default", "Roles", "DefaultBroadcastArea"] }, params));
                    user = JSON.parse(localStorage.getItem("user"));
                    if (!tempParams) return [3 /*break*/, 12];
                    return [4 /*yield*/, Client.searchVertexTyped(lnk_AdvertisingCompanySupport, tempParams)];
                case 11:
                    _c = _e.sent();
                    return [3 /*break*/, 13];
                case 12:
                    _c = [];
                    _e.label = 13;
                case 13:
                    newOptions = _c;
                    if (!((user === null || user === void 0 ? void 0 : (_user$customer2 = user.customer) === null || _user$customer2 === void 0 ? void 0 : _user$customer2.Authorization) == "MediaManager")) return [3 /*break*/, 15];
                    return [4 /*yield*/, Client.searchVertexTyped(ref_SubAgencies)];
                case 14:
                    _d = _e.sent();
                    return [3 /*break*/, 16];
                case 15:
                    _d = [];
                    _e.label = 16;
                case 16:
                    subAgencies = _d;
                    if (message.Start) {
                        newOptions = newOptions.filter(function (lnk) { return DateZone(lnk.Start) <= message.Start && (!lnk.End || message.Start <= DateZone(lnk.End)) && DateZone(lnk["ValidStart"]) <= message.Start && (!lnk["ValidEnd"] || message.Start <= DateZone(lnk["ValidEnd"])); });
                    }
                    options_2 = __spreadArray(__spreadArray(__spreadArray([], newOptions, true), subAgencies, true), barterCompanies, true).sort(function (a, b) { return a.Name.localeCompare(b.Name); });
                    setDefaultValue = function () { return __awaiter(_this, void 0, void 0, function () { var _options4, _options5, _options, defaultVal, _options6, found; return __generator(this, function (_a) {
                        if (((_options4 = options_2) === null || _options4 === void 0 ? void 0 : _options4.length) === 1)
                            return [2 /*return*/, setValue_2(options_2[0])];
                        _options = (_options5 = options_2) === null || _options5 === void 0 ? void 0 : _options5.filter(function (o) { return o["@class"] == lnk_AdvertisingCompanySupport.name; });
                        if (message[property]) {
                            defaultVal = _options === null || _options === void 0 ? void 0 : _options.find(function (v) { return v.outRid == message[property]; });
                            if (!defaultVal)
                                defaultVal = (_options6 = options_2) === null || _options6 === void 0 ? void 0 : _options6.find(function (v) { return v["@rid"] == message[property]; });
                        }
                        if (!defaultVal)
                            defaultVal = _options === null || _options === void 0 ? void 0 : _options.find(function (v) { return v.Default; });
                        if (!defaultVal && message.BroadcastArea) {
                            found = _options === null || _options === void 0 ? void 0 : _options.find(function (e) { return e.DefaultBroadcastArea == message.BroadcastArea; });
                            if (found) {
                                message[property] = found.in;
                                return [2 /*return*/];
                            }
                        }
                        setValue_2(defaultVal);
                        return [2 /*return*/];
                    }); }); };
                    if (!(currentOptions === undefined)) return [3 /*break*/, 18];
                    if (!message[property]) return [3 /*break*/, 18];
                    defaultValue = (_options7 = options_2) === null || _options7 === void 0 ? void 0 : _options7.find(function (o) { return o["@rid"] == message[property] || o.outRid == message[property]; });
                    if (!!defaultValue) return [3 /*break*/, 18];
                    console.log("add inexising");
                    return [4 /*yield*/, Client.searchVertexTyped(ref_AdvertisingCompanies, { "@rid": message[property] })];
                case 17:
                    missingValue = (_e.sent())[0];
                    if (missingValue) {
                        missingValue.warning = Trad('data_not_found');
                        options_2 = __spreadArray([missingValue], options_2, true);
                    }
                    _e.label = 18;
                case 18: return [4 /*yield*/, setDefaultValue()];
                case 19:
                    _e.sent();
                    _e.label = 20;
                case 20: return [2 /*return*/, options_2];
                case 21:
                    error_10 = _e.sent();
                    console.log("error UpdateAdvertisingCompany", error_10);
                    return [3 /*break*/, 22];
                case 22: return [2 /*return*/];
            }
        });
    });
} // Action creators are generated for each case reducer function
export var setMessageMode = (_a = messageEditorSlice.actions, _a.setMessageMode), setCurrentNetType = _a.setCurrentNetType, setMessageSync = _a.setMessageSync, setMessagePublications = _a.setMessagePublications, setStart = _a.setStart, lockNext = _a.lockNext, unlockNext = _a.unlockNext, toggleRepeat = _a.toggleRepeat, disableRepeat = _a.disableRepeat, clearMessageEditor = _a.clearMessageEditor;
export var messageEditorReducer = messageEditorSlice.reducer;
