import { configureStore } from '@reduxjs/toolkit'; // import adminReducer from '../reducers/adminReducer'
import authReducer from '../reducers/authReducer';
import importsReducer from '../reducers/importsReducer';
import searchReducer from '../reducers/searchReducer';
import supportReducer from '../reducers/supportReducer';
import { gridReducer } from './gridSlice';
import { linkMgrReducer } from './linkMgrSlice';
import { storageReducer } from './storageReducer';
import { tooltipReducer } from './tooltipSlice';
import { referentialTreeReducer } from './referentialTreeSlice';
import { messageEditorReducer, setMessage, setMessageMode, setMessagePublications, setMessageSync } from './messageEditorSlice';
import { treeReducer } from './treeSlice';
import { backgroundReducer } from './backgroundSlice';
import { projectReducer } from './projectSlice';
import { connectedStatusReducer } from './connectedStatusSlice';
import { agreementEditorReducer } from './agreementEditorSlice';
import { percentageSimilarityReducer } from './percentageSimilaritySlice';
import { tcdReducer } from './tcdSlice';
import { supplierReducer } from './supplierSlice';
import { campaignEditorReducer, setCampaign, setCampaignMode, setCampaignPublications, setCampaignSync } from './campaignEditorSlice';
import { gridConfigurationsReducer } from './gridConfigurationsSlice';
import { offerReducer } from './offerSlice';
export var store = configureStore({ reducer: { offer: offerReducer, tcd: tcdReducer, tooltip: tooltipReducer, auth: authReducer, grid: gridReducer, verticesStorage: storageReducer, linkMgr: linkMgrReducer, tree: treeReducer, globalSearch: searchReducer, support: supportReducer,
        imports: importsReducer, referentialTree: referentialTreeReducer, campaignEditor: campaignEditorReducer, messageEditor: messageEditorReducer, agreementEditor: agreementEditorReducer, background: backgroundReducer, project: projectReducer, columsConfigurations: gridConfigurationsReducer, connectedStatus: connectedStatusReducer, similarityPercentage: percentageSimilarityReducer, supplier: supplierReducer }, devTools: process.env.NODE_ENV !== 'production' && { name: "App", trace: true }, middleware: function (getDefaultMiddleware) { return getDefaultMiddleware({ serializableCheck: false }); } });
export var GetEditorState = function (modelName, root) { return modelName == "ref_Messages" ? root.messageEditor : root.campaignEditor; };
export var SetMode = function (modelName, mode) { return modelName == "ref_Messages" ? store.dispatch(setMessageMode(mode)) : store.dispatch(setCampaignMode(mode)); };
export var SetModel = function (modelName, data) { return modelName == "ref_Messages" ? store.dispatch(setMessage(data)) : store.dispatch(setCampaign(data)); };
export var SetModelSync = function (modelName, data) { return modelName == "ref_Messages" ? store.dispatch(setMessageSync(data)) : store.dispatch(setCampaignSync(data)); };
export var SetPublications = function (modelName, data) { return modelName == "ref_Messages" ? store.dispatch(setMessagePublications(data)) : store.dispatch(setCampaignPublications(data)); };
export var getScheduler = function (modelName) { return getSchedulerSelector(modelName)(store.getState()); };
export var getSchedulerSelector = function (modelName) { return function (state) { var _state$columsConfigur, _state$columsConfigur2, _state$columsConfigur3; return (_state$columsConfigur = state.columsConfigurations) === null || _state$columsConfigur === void 0 ? void 0 : (_state$columsConfigur2 = _state$columsConfigur.configurations) === null || _state$columsConfigur2 === void 0 ? void 0 : (_state$columsConfigur3 = _state$columsConfigur2[modelName]) === null || _state$columsConfigur3 === void 0 ? void 0 : _state$columsConfigur3.scheduler; }; };
export var getTable = function (modelName) { return getTableSelector(modelName)(store.getState()); };
export var getTableSelector = function (modelName) { return function (state) { var _state$columsConfigur4, _state$columsConfigur5, _state$columsConfigur6; return (_state$columsConfigur4 = state.columsConfigurations) === null || _state$columsConfigur4 === void 0 ? void 0 : (_state$columsConfigur5 = _state$columsConfigur4.configurations) === null || _state$columsConfigur5 === void 0 ? void 0 : (_state$columsConfigur6 = _state$columsConfigur5[modelName]) === null || _state$columsConfigur6 === void 0 ? void 0 : _state$columsConfigur6.table; }; };
export var getExportSelector = function (modelName) { return function (state) { var _state$columsConfigur7, _state$columsConfigur8, _state$columsConfigur9; return (_state$columsConfigur7 = state.columsConfigurations) === null || _state$columsConfigur7 === void 0 ? void 0 : (_state$columsConfigur8 = _state$columsConfigur7.configurations) === null || _state$columsConfigur8 === void 0 ? void 0 : (_state$columsConfigur9 = _state$columsConfigur8[modelName]) === null || _state$columsConfigur9 === void 0 ? void 0 : _state$columsConfigur9.export; }; }; // Infer the `RootState` and `AppDispatch` types from the store itself
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
