var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Client, ClientHUB, redirectionFinder, URLAdwoneProvider, URLServerProvider } from "hub-lib/client/client.bin"; // import io from 'socket.io-client';
import version from './version';
import { eSocketCommand, eSocketMsg } from "hub-lib/models/socket.bin";
import { closeNotify, Notify } from "./utils/Notify.bin";
import { RightManager } from "hub-lib/models/types/rights.bin";
import numbers from 'cldr-numbers-full/main/fr/numbers.json';
import caGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/fr/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/fr/timeZoneNames.json';
import numbersGB from 'cldr-numbers-full/main/en-GB/numbers.json';
import caGregorianGB from 'cldr-dates-full/main/en-GB/ca-gregorian.json';
import dateFieldsGB from 'cldr-dates-full/main/en-GB/dateFields.json';
import timeZoneNamesGB from 'cldr-dates-full/main/en-GB/timeZoneNames.json';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import { load } from '@progress/kendo-react-intl';
import { ImportsStatus } from "hub-lib/dto/admin/ref_Imports.bin";
import { memoizeAsync, SetReleaseEventLoopOverride } from "hub-lib/tools.bin";
import { TooltipManager as TooltipManagerAdw } from "adwone-lib/index";
import { TooltipManager } from "./components/CustomTooltip";
import { DataProvider } from 'hub-lib/provider';
import { maskRole } from "hub-lib/business/rights/rights.bin";
import { GetServerUrlFromFrontUrl } from "adw-url-provider";
load(likelySubtags, currencyData, weekData, numbers, caGregorian, dateFields, timeZoneNames, numbersGB, caGregorianGB, dateFieldsGB, timeZoneNamesGB);
SetReleaseEventLoopOverride(function () { });
axios.interceptors.response.use(function (response) { var respData = response.data; if (ErrorRedirection.IsErrorRedirection(respData))
    window.location.href = respData.url; return response; });
TooltipManagerAdw.Push = function (p) { return TooltipManager.Push(p); };
RightManager.GetUser = function () { return JSON.parse(localStorage.getItem('user')); };
RightManager.rightsProvider = function () { var _JSON$parse; return (_JSON$parse = JSON.parse(localStorage.getItem('user'))) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.userPermissions; };
RightManager.roleProvider = function () { var user = JSON.parse(localStorage.getItem('user')); var roleName = Object.keys(maskRole).find(function (key) { return maskRole[key] & (user === null || user === void 0 ? void 0 : user.maskRights); }); return roleName; };
var searchDecorator = function (params) { if (params !== null && params !== void 0 && params.Group) {
    delete params.AdvertiserGroup;
    delete params.Advertiser;
    delete params.Brand;
    delete params.Product;
} return params; };
URLAdwoneProvider.provide = function () { return window.location.origin; };
export var redirections = { // "/exemple/big/path": "url", // Full path
// [ref_Trad.name]: { // Splited path
//     "search": "https://server.adwone.com",
// },
// [ref_Trad.name]: DefaultProdURLServer,
};
URLServerProvider.provide = function (path) {
    var _window; // Custom URL for path
    // full patch then splited path
    if (path) {
        var redirectionFind = redirections[path] || redirectionFinder(redirections, path.split("/").filter(function (x) { return x; }));
        if (redirectionFind)
            return redirectionFind;
    }
    return GetServerUrlFromFrontUrl((_window = window) === null || _window === void 0 ? void 0 : _window.origin);
};
DataProvider.search = function (vertexName, params, op) { return __awaiter(void 0, void 0, void 0, function () { var _res$data, res; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, Client.searchVertex(vertexName, __assign(__assign({}, params), { _operators: op }))];
        case 1:
            res = _a.sent();
            return [2 /*return*/, res === null || res === void 0 ? void 0 : (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.results];
    }
}); }); };
var searchMemoized = null;
DataProvider.searchMemoized = function (vertexName, params, op) { if (!searchMemoized)
    searchMemoized = memoizeAsync(function (_ref) { var _v = _ref._v, _p = _ref._p, _o = _ref._o; return DataProvider.search(_v, _p, _o); }); return searchMemoized({ _v: vertexName, _p: params, _o: op }); };
DataProvider.getMetadata = function (vertexName, all) { return __awaiter(void 0, void 0, void 0, function () {
    var _await$Client$getMeta, _await$Client$getMeta2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!all)
                    all = false;
                return [4 /*yield*/, Client.getMetadata(vertexName, all)];
            case 1: return [2 /*return*/, (_await$Client$getMeta = _a.sent()) === null || _await$Client$getMeta === void 0 ? void 0 : (_await$Client$getMeta2 = _await$Client$getMeta.data) === null || _await$Client$getMeta2 === void 0 ? void 0 : _await$Client$getMeta2.results];
        }
    });
}); };
DataProvider.getOJDWaves = function (params) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, Client.getWaves(params)];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); };
DataProvider.getOJD = function (params) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, Client.getOjd(params)];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); };
DataProvider.getVwMMDiscountClasses = function () { return __awaiter(void 0, void 0, void 0, function () {
    var _ref2, _views$data, sourceMM, views;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                sourceMM = GetSrcMMUser();
                if (!sourceMM)
                    return [2 /*return*/, []]; // Get MM View
                return [4 /*yield*/, Client.searchVertex(vw_mm_HasDiscountClass.name, { in: sourceMM["@rid"], properties: ['out.ExternalID as ExternalID', 'Referential'] })];
            case 1:
                views = _a.sent();
                return [2 /*return*/, (_ref2 = views === null || views === void 0 ? void 0 : (_views$data = views.data) === null || _views$data === void 0 ? void 0 : _views$data.results) !== null && _ref2 !== void 0 ? _ref2 : []]; // const sources = await DataProvider.search<src_MM>(src_MM.name, { properties: ['@rid'] });
        }
    });
}); };
var focused = true;
window.onfocus = function () { focused = true; };
window.onblur = function () { focused = false; };
Client.notificationEvent.addListener(eSocketMsg.notification, function (body) { if (focused || body.force)
    Notify(body.message, body.messageType); });
import { store } from "./redux/store";
import { finishImports, refreshImports } from "./actions/importsActions";
import { GetCurrentLocale, SetCurrentLocale, Trad, TradProvider } from "trad-lib";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import { ref_Agreements } from "hub-lib/dto/client/ref_Agreements.bin";
import { keyStoreTrads } from "./config.bin";
import { GetSrcMMUser, GetUser } from "./utils/localstorage.bin";
import { eReportStatus } from "hub-lib/dto/client/ref_Reports";
import { ErrorRedirection } from "hub-lib/errors/types";
import { vw_mm_HasDiscountClass } from "hub-lib/models/orientdb/vw_mm_HasDiscountClass.bin";
ClientHUB.AddSearchDecorator({ vertex: ref_Messages.name, decorate: searchDecorator });
ClientHUB.AddSearchDecorator({ vertex: ref_Campaigns.name, decorate: searchDecorator });
ClientHUB.AddSearchDecorator({ vertex: ref_Agreements.name, decorate: searchDecorator });
var count = 0;
var checkVersion = function () { return __awaiter(void 0, void 0, void 0, function () {
    var _GetUser, v, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                if (!((_GetUser = GetUser()) !== null && _GetUser !== void 0 && _GetUser["@rid"]))
                    return [2 /*return*/];
                return [4 /*yield*/, Client.getHubVersion()];
            case 1:
                v = (_a.sent()).data;
                if (version != v) {
                    Notify(Trad("version_deprecated"), "warning");
                    if (count % 4 == 0) // once every 20 minutes
                        Client.log({ Category: "version deprecated", Action: version, Informations: version });
                    count++;
                }
                return [3 /*break*/, 3];
            case 2:
                error_1 = _a.sent();
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
Client.notificationEvent.addListener(eSocketMsg.command, function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var _body$infos, _body$infos2, _body$infos3, _body$infos3$Report, _body$infos4, _body$infos4$Report, _a, localStorageData, i, key, task, _b, cron, report, _report$Errors, _report$Errors2, _cron$TaskParams$Noti;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = body.command;
                switch (_a) {
                    case eSocketCommand.logVersionClient: return [3 /*break*/, 1];
                    case eSocketCommand.sendLocalstorage: return [3 /*break*/, 3];
                    case eSocketCommand.clearCacheSession: return [3 /*break*/, 5];
                    case eSocketCommand.progress: return [3 /*break*/, 7];
                }
                return [3 /*break*/, 8];
            case 1: return [4 /*yield*/, Client.log({ Category: "version", Action: version, Informations: version })];
            case 2:
                _c.sent();
                return [3 /*break*/, 9];
            case 3:
                localStorageData = {};
                for (i = 0; i < localStorage.length; i++) {
                    key = localStorage.key(i);
                    if (key != keyStoreTrads)
                        localStorageData[key] = localStorage.getItem(key);
                }
                return [4 /*yield*/, Client.log({ Category: "localstorage", Action: "get", Informations: localStorageData })];
            case 4:
                _c.sent();
                return [3 /*break*/, 9];
            case 5: return [4 /*yield*/, Client.getUser(true)];
            case 6:
                _c.sent();
                Notify(Trad("your_account_modified"), "warning");
                return [3 /*break*/, 9];
            case 7:
                if ((_body$infos = body.infos) !== null && _body$infos !== void 0 && _body$infos.taskId) {
                    task = body.infos;
                    Notify(task.message, "info", { progress: { Current: task.current, Total: task.total, taskId: task.taskId } });
                    return [3 /*break*/, 9];
                }
                if ((_body$infos2 = body.infos) !== null && _body$infos2 !== void 0 && _body$infos2.cron) {
                    _b = body.infos, cron = _b.cron, report = _b.report;
                    if (report.Progress.Status === eReportStatus.DONE) {
                        if (cron.TaskParams.EstimateID) {
                            if (((_report$Errors = report.Errors) === null || _report$Errors === void 0 ? void 0 : _report$Errors.length) === report.Progress.Total) {
                                Notify(Trad("no_message_deversed"), "error");
                            }
                            else if (cron.TaskParams.EstimateType !== "Delete") {
                                Notify("".concat(Trad("campaign_deversed"), " ").concat(report.Progress.Current - ((_report$Errors2 = report.Errors) === null || _report$Errors2 === void 0 ? void 0 : _report$Errors2.length), "/").concat(report.Progress.Total), "success");
                            }
                            else {
                                Notify(Trad("estimate_updated"), "success");
                            }
                        }
                        closeNotify();
                    }
                    else {
                        Notify(Trad((_cron$TaskParams$Noti = cron.TaskParams.NotificationMessage) !== null && _cron$TaskParams$Noti !== void 0 ? _cron$TaskParams$Noti : "please_wait_sending"), "info", { progress: report.Progress });
                    }
                }
                if (((_body$infos3 = body.infos) === null || _body$infos3 === void 0 ? void 0 : (_body$infos3$Report = _body$infos3.Report) === null || _body$infos3$Report === void 0 ? void 0 : _body$infos3$Report.Status) == ImportsStatus.IN_PROGRESS)
                    store.dispatch(refreshImports(body.infos, body.forceUpdate));
                else if ([ImportsStatus.PENDING, ImportsStatus.FAILED, ImportsStatus.DONE, ImportsStatus.IMPORTING].includes((_body$infos4 = body.infos) === null || _body$infos4 === void 0 ? void 0 : (_body$infos4$Report = _body$infos4.Report) === null || _body$infos4$Report === void 0 ? void 0 : _body$infos4$Report.Status))
                    store.dispatch(finishImports(body.infos));
                return [3 /*break*/, 9];
            case 8: // unkwnown
            return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}); });
Client.notificationEvent.addListener(eSocketMsg.action, function (body) { return __awaiter(void 0, void 0, void 0, function () { var _body$options$type, _body$options; return __generator(this, function (_a) {
    Notify(body.message, (_body$options$type = body === null || body === void 0 ? void 0 : (_body$options = body.options) === null || _body$options === void 0 ? void 0 : _body$options.type) !== null && _body$options$type !== void 0 ? _body$options$type : "warning", { action: { label: Trad(body.service), onclick: function () { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = body.service;
                        switch (_a) {
                            case "create": return [3 /*break*/, 1];
                            case "update": return [3 /*break*/, 3];
                            case "delete": return [3 /*break*/, 5];
                        }
                        return [3 /*break*/, 7];
                    case 1: return [4 /*yield*/, Client.createVertex(body.vertex, body.body)];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 7];
                    case 3: return [4 /*yield*/, Client.updateVertex(body.vertex, body.body)];
                    case 4:
                        _b.sent();
                        return [3 /*break*/, 7];
                    case 5: return [4 /*yield*/, Client.deleteVertex(body.vertex, body.body)];
                    case 6:
                        _b.sent();
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            }); }); } } });
    return [2 /*return*/];
}); }); }); // let socket: SocketIOClient.Socket = null;
// let loading = false;
// let reconnectTimeout = null;
// const consoleSocketLog = (...args) => {
//     if (IsDebugMode())
//         console.log('[Socket]', ...args);
// }
var alreadyHandled = new Set(); // uniq id for each tab
import { v4 as uuidv4 } from 'uuid';
var uuid = uuidv4();
export var listenLogs = function () { return __awaiter(void 0, void 0, void 0, function () {
    var res, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 8];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 5, , 6]);
                if (!GetUser()) return [3 /*break*/, 4];
                return [4 /*yield*/, Client.Post("/notifications", { uuid: uuid })];
            case 2: return [4 /*yield*/, (_a.sent()).data];
            case 3:
                res = _a.sent();
                if (res.uuid) {
                    if (alreadyHandled.has(res.uuid))
                        return [3 /*break*/, 0];
                    alreadyHandled.add(res.uuid);
                }
                HandleNotification(res);
                channel.postMessage({ type: eRequestType.notification, data: res }); // socket.on(eSocketMsg.notification, (body: any) => {
                //                 consoleSocketLog('[Socket] notification', body);
                //                 Client.notificationEvent.emit(eSocketMsg.notification, body);
                //             });
                //             socket.on(eSocketMsg.command, (body: any) => {
                //                 consoleSocketLog('[Socket] command', body);
                //                 Client.notificationEvent.emit(eSocketMsg.command, body);
                //             });
                //             socket.on(eSocketMsg.action, (body: any) => {
                //                 Client.notificationEvent.emit(eSocketMsg.action, body);
                //             });
                return [3 /*break*/, 0];
            case 4: return [3 /*break*/, 6];
            case 5:
                error_2 = _a.sent();
                return [3 /*break*/, 6];
            case 6: return [4 /*yield*/, new Promise(function (r) { return setTimeout(r, 30000); })];
            case 7:
                _a.sent();
                return [3 /*break*/, 0];
            case 8: return [2 /*return*/];
        }
    });
}); }; // listenLogs();
// listenLogs();
// import './LongPolling';
import "./LongPolling";
import { channel, eRequestType } from "./LongPolling";
import axios from "axios";
Client.onGetStatus.addListener("GET", function (user) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/];
    });
}); });
Client.onGetStatus.addListener("DELETE", function () {
    // socket = undefined;
});
export function HandleNotification(res) { switch (res.status) {
    case eSocketMsg.notification:
        Client.notificationEvent.emit(eSocketMsg.notification, res.body);
        break;
    case eSocketMsg.command:
        Client.notificationEvent.emit(eSocketMsg.command, res.body);
        break;
    case eSocketMsg.action:
        Client.notificationEvent.emit(eSocketMsg.action, res.body);
        break;
} }
export function initialize() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, TradProvider.GetInstance().Init()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, SetCurrentLocale(GetCurrentLocale())];
                case 2:
                    _a.sent();
                    checkVersion();
                    setInterval(checkVersion, 1000 * 60 * 5); // 5 minutes
                    return [2 /*return*/];
            }
        });
    });
}
