var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Client } from "hub-lib/client/client.bin";
import { AdvertisingCompanyExtended } from "hub-lib/dto/referential/AdvertisingCompanyExtended.bin";
import { BroadcastAreaExtended } from "hub-lib/dto/referential/BroadcastAreaExtended.bin";
import { lnk_HasCurrency } from "hub-lib/models/orientdb/lnk_HasCurrency.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { ref_Circulations } from "hub-lib/dto/client/ref_Circulations";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { Typed, compareObjects, distinct, extractSub, hasOwnProperty } from "hub-lib/tools.bin";
import { Trad } from "trad-lib";
import { ConsoleDebug, GetUser } from "../utils/localstorage.bin";
import { lnk_AdvertisingCompanySupport } from "hub-lib/models/orientdb/lnk_AdvertisingCompanySupport.bin";
import { lnk_HasBroadcastArea } from "hub-lib/models/orientdb/lnk_HasBroadcastArea.bin";
import { ref_Groups } from "hub-lib/dto/client/ref_Groups.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ref_Companies } from "hub-lib/models/orientdb/ref_Companies.bin";
import { ref_Agencies } from "hub-lib/models/orientdb/ref_Agencies.bin";
import { DataProvider } from "hub-lib/provider";
import { Notify } from "../utils/Notify.bin";
import { KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import { changeEndValue } from "../components/VertexGrid/Generic/ModelForm/DiffusionEditor";
import { NameSort } from "format-lib/index.bin";
export var otherRid = "#-1:-1";
export function GetMandataires() {
    return __awaiter(this, void 0, void 0, function () {
        var _GetUser, _GetUser$customer, agencyOptions, isSellingMode, _GetUser2, ridCustomerCompany_1, customerCompany;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Client.searchVertexTyped(ref_Agencies, { "Active": true })];
                case 1:
                    agencyOptions = _a.sent();
                    isSellingMode = ((_GetUser = GetUser()) === null || _GetUser === void 0 ? void 0 : (_GetUser$customer = _GetUser.customer) === null || _GetUser$customer === void 0 ? void 0 : _GetUser$customer.Activity) == 'Selling';
                    if (!isSellingMode) return [3 /*break*/, 3];
                    ridCustomerCompany_1 = (_GetUser2 = GetUser()) === null || _GetUser2 === void 0 ? void 0 : _GetUser2.customer.Company;
                    if (!!agencyOptions.some(function (a) { return a["@rid"] == ridCustomerCompany_1; })) return [3 /*break*/, 3];
                    return [4 /*yield*/, Client.searchVertexTyped(ref_Companies, { "Active": true, "@rid": ridCustomerCompany_1, properties: ["Name"] })];
                case 2:
                    customerCompany = (_a.sent())[0];
                    if (customerCompany)
                        agencyOptions.unshift(customerCompany);
                    _a.label = 3;
                case 3:
                    agencyOptions.push({ Name: Trad("other"), "@rid": otherRid });
                    return [2 /*return*/, agencyOptions];
            }
        });
    });
}
export function UpdateAgencyState(supportEditor, data) {
    return __awaiter(this, void 0, void 0, function () { return __generator(this, function (_a) {
        if (supportEditor.agencyOptions == undefined) {
            ConsoleDebug('UpdateAgencyState');
            return [2 /*return*/, GetMandataires()];
        }
        return [2 /*return*/, supportEditor.agencyOptions];
    }); });
}
export function UpdateCurrencyState(supportEditor, data) {
    return __awaiter(this, void 0, void 0, function () {
        var _supportEditor$data, _a, BroadcastArea, Support, AdvCompany_Com, currencyOptions, setCurrency;
        var _this = this;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = (_supportEditor$data = supportEditor.data) !== null && _supportEditor$data !== void 0 ? _supportEditor$data : {}, BroadcastArea = _a.BroadcastArea, Support = _a.Support, AdvCompany_Com = _a.AdvCompany_Com;
                    currencyOptions = undefined;
                    if (!(data.Support && (supportEditor.currencyOptions == undefined || BroadcastArea !== data.BroadcastArea || Support !== data.Support || AdvCompany_Com != data.AdvCompany_Com))) return [3 /*break*/, 2];
                    ConsoleDebug('UpdateCurrencyState');
                    setCurrency = function () { return __awaiter(_this, void 0, void 0, function () {
                        var _await$Client$searchV, _advertisingCompany, _advertisingCompany$l, _currencyOptions3, _currencyOptions6, setValue, lastBroadcastArea, parents, _broadcastArea$lnkCur, broadcastArea, parent, advertisingCompany, res, links, currencies_1, _currencyOptions, defaultValue, missingValue, error_1, broadcastAreaWithCurrency, _currencyOptions2, found, _advertisingCompany2, _advertisingCompany2$, links_1, _links$find, _currencyOptions4, link_1, opt, _currencyOptions5, currencies, defaultCurrency, defaultVal;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    setValue = function (value) { data.Currency = value === null || value === void 0 ? void 0 : value["@rid"]; return value; };
                                    lastBroadcastArea = data.BroadcastArea;
                                    if (!data.BroadcastArea) return [3 /*break*/, 3];
                                    parents = new Set();
                                    _a.label = 1;
                                case 1:
                                    if (!true) return [3 /*break*/, 3];
                                    return [4 /*yield*/, Client.searchVertexTyped(BroadcastAreaExtended, { "@rid": lastBroadcastArea, parent: true })];
                                case 2:
                                    broadcastArea = (_a.sent())[0];
                                    if (!broadcastArea)
                                        return [3 /*break*/, 3];
                                    lastBroadcastArea = broadcastArea["@rid"];
                                    if ((_broadcastArea$lnkCur = broadcastArea.lnkCurrencies) !== null && _broadcastArea$lnkCur !== void 0 && _broadcastArea$lnkCur.length)
                                        return [3 /*break*/, 3];
                                    parent = broadcastArea === null || broadcastArea === void 0 ? void 0 : broadcastArea.parent;
                                    if (!parent || parents.has(parent))
                                        return [3 /*break*/, 3];
                                    lastBroadcastArea = parent;
                                    parents.add(parent);
                                    return [3 /*break*/, 1];
                                case 3:
                                    advertisingCompany = null;
                                    if (!data.AdvCompany_Com) return [3 /*break*/, 5];
                                    return [4 /*yield*/, Client.searchVertexTyped(AdvertisingCompanyExtended, { "@rid": data.AdvCompany_Com })];
                                case 4:
                                    advertisingCompany = (_await$Client$searchV = _a.sent()) === null || _await$Client$searchV === void 0 ? void 0 : _await$Client$searchV[0]; // Récupération des liens devises pour le support et la zone de diffusion qui possède une devise
                                    _a.label = 5;
                                case 5: return [4 /*yield*/, Client.searchVertexTyped(lnk_HasCurrency, { Active: true, in: [data.Support, lastBroadcastArea].filter(function (e) { return e; }), properties: ["*", "out.Name as Name", "out.Code as Code", "out.@rid as outRid"] })];
                                case 6:
                                    res = _a.sent();
                                    currencyOptions = res.map(function (r) { return Typed({ "@rid": r.outRid, Name: r.Name, Code: r.Code, Default: r.Default, link: r.in }); }); //Ajout des devises provenant de la régie commerciale
                                    links = (_advertisingCompany = advertisingCompany) === null || _advertisingCompany === void 0 ? void 0 : (_advertisingCompany$l = _advertisingCompany.lnkBroadcastAreas) === null || _advertisingCompany$l === void 0 ? void 0 : _advertisingCompany$l.filter(function (m) { return m.DefaultCurrency && m.out == data.BroadcastArea; });
                                    if (!(links !== null && links !== void 0 && links.length)) return [3 /*break*/, 8];
                                    return [4 /*yield*/, Client.searchVertexTyped(ref_Currencies, { "@rid": links.map(function (l) { return l.DefaultCurrency; }) })];
                                case 7:
                                    currencies_1 = _a.sent();
                                    currencyOptions = __spreadArray(__spreadArray([], currencyOptions, true), links.map(function (r) { var currency = currencies_1.find(function (c) { return c["@rid"] == r.DefaultCurrency; }); if (!currency)
                                        return null; return Typed({ "@rid": r.DefaultCurrency, Name: currency.Name, Code: currency.Code, Default: r.Default, link: r.in }); }).filter(Boolean), true);
                                    _a.label = 8;
                                case 8:
                                    _a.trys.push([8, 11, , 12]);
                                    if (!(supportEditor.currencyOptions === undefined && data.Currency)) return [3 /*break*/, 10];
                                    defaultValue = (_currencyOptions = currencyOptions) === null || _currencyOptions === void 0 ? void 0 : _currencyOptions.find(function (o) { return o["@rid"] == data.Currency || o.outRid == data.Currency; });
                                    if (!!defaultValue) return [3 /*break*/, 10];
                                    console.log("add inexising currency");
                                    return [4 /*yield*/, Client.searchVertexTyped(ref_Currencies, { "@rid": data.Currency })];
                                case 9:
                                    missingValue = (_a.sent())[0];
                                    if (missingValue) {
                                        missingValue.warning = Trad('data_not_found');
                                        currencyOptions = __spreadArray([missingValue], currencyOptions, true);
                                    }
                                    _a.label = 10;
                                case 10: return [3 /*break*/, 12];
                                case 11:
                                    error_1 = _a.sent();
                                    console.log("error", error_1);
                                    return [3 /*break*/, 12];
                                case 12:
                                    broadcastAreaWithCurrency = lastBroadcastArea;
                                    if (data.Currency) {
                                        found = (_currencyOptions2 = currencyOptions) === null || _currencyOptions2 === void 0 ? void 0 : _currencyOptions2.find(function (v) { return v["@rid"] == data.Currency; });
                                        if (found)
                                            return [2 /*return*/, setValue(found)];
                                    } // Si une seule devise dans la liste alors on l'affecte à la data
                                    if (((_currencyOptions3 = currencyOptions) === null || _currencyOptions3 === void 0 ? void 0 : _currencyOptions3.length) === 1)
                                        return [2 /*return*/, setValue(currencyOptions[0])]; // Si il y a une devise par défaut pour la régie commerciale sur la zone de diff de la data alors on l'affecte à la data
                                    if (advertisingCompany) {
                                        links_1 = (_advertisingCompany2 = advertisingCompany) === null || _advertisingCompany2 === void 0 ? void 0 : (_advertisingCompany2$ = _advertisingCompany2.lnkBroadcastAreas) === null || _advertisingCompany2$ === void 0 ? void 0 : _advertisingCompany2$.filter(function (l) { return l.out == data.BroadcastArea && l.DefaultCurrency; });
                                        if (links_1.length) {
                                            link_1 = (_links$find = links_1.find(function (l) { return l.Default; })) !== null && _links$find !== void 0 ? _links$find : links_1[0];
                                            opt = (_currencyOptions4 = currencyOptions) === null || _currencyOptions4 === void 0 ? void 0 : _currencyOptions4.find(function (o) { return o["@rid"] == link_1.DefaultCurrency; });
                                            if (opt)
                                                return [2 /*return*/, setValue(opt)];
                                        }
                                    } // On affecte la devise associée à la zone de diffusion de la data
                                    if (broadcastAreaWithCurrency) {
                                        currencies = (_currencyOptions5 = currencyOptions) === null || _currencyOptions5 === void 0 ? void 0 : _currencyOptions5.filter(function (e) { return e.link == broadcastAreaWithCurrency; });
                                        if (currencies.length == 1)
                                            return [2 /*return*/, setValue(currencies[0])];
                                        defaultCurrency = currencies.find(function (e) { return e.Default; });
                                        if (defaultCurrency)
                                            return [2 /*return*/, setValue(defaultCurrency)];
                                    }
                                    defaultVal = (_currencyOptions6 = currencyOptions) === null || _currencyOptions6 === void 0 ? void 0 : _currencyOptions6.find(function (v) { if (data.Currency)
                                        return v["@rid"] == data.Currency; if (v.Default)
                                        return Boolean(setValue(v)); return false; });
                                    setValue(defaultVal);
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    return [4 /*yield*/, setCurrency()];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2: return [2 /*return*/, currencyOptions && distinct(currencyOptions, function (e) { return e['@rid']; })];
            }
        });
    });
}
export function UpdateBroadcastState(supportEditor, data) {
    return __awaiter(this, void 0, void 0, function () {
        var _supportEditor$data2, broadcastOptions, Support, dispatchChanged_1, lnkAdv_1, adv, broadcastAreas_1, setDefault;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    broadcastOptions = undefined;
                    Support = ((_supportEditor$data2 = supportEditor.data) !== null && _supportEditor$data2 !== void 0 ? _supportEditor$data2 : {}).Support;
                    if (!(data.Support && (supportEditor.broadcastOptions == undefined || Support !== data.Support))) return [3 /*break*/, 5];
                    ConsoleDebug('UpdateBroadcastState');
                    dispatchChanged_1 = function (broadcastArea) { if (broadcastArea) {
                        data.BroadcastArea = broadcastArea === null || broadcastArea === void 0 ? void 0 : broadcastArea["@rid"];
                    } };
                    return [4 /*yield*/, Client.searchVertexTyped(lnk_AdvertisingCompanySupport, { "out": data.Support })];
                case 1:
                    lnkAdv_1 = _a.sent();
                    adv = distinct(lnkAdv_1.map(function (l) { return l.in; }), function (e) { return e; });
                    return [4 /*yield*/, Client.searchVertexTyped(lnk_HasBroadcastArea, { "in": __spreadArray([data.Support], adv, true) })];
                case 2:
                    broadcastAreas_1 = _a.sent();
                    return [4 /*yield*/, Client.searchVertexTyped(ref_BroadcastAreas, { Active: true, "@rid": distinct(broadcastAreas_1.map(function (l) { return l.out; }), function (e) { return e; }), properties: ["@rid", "Name"] })];
                case 3:
                    broadcastOptions = _a.sent();
                    setDefault = function () { return __awaiter(_this, void 0, void 0, function () {
                        var _broadcastOptions, selectedElement, advDefault, _broadcastAreas$find, found_1, broadcastAdvDefault_1, defaultElement;
                        return __generator(this, function (_a) {
                            if (broadcastOptions.length == 1) {
                                return [2 /*return*/, dispatchChanged_1(broadcastOptions[0])];
                            }
                            selectedElement = (_broadcastOptions = broadcastOptions) === null || _broadcastOptions === void 0 ? void 0 : _broadcastOptions.find(function (o) { return o["@rid"] == data.BroadcastArea; });
                            if (selectedElement)
                                return [2 /*return*/, dispatchChanged_1(selectedElement)]; // recherche les régies associées au support
                            advDefault = distinct(lnkAdv_1.map(function (lnk) { return lnk.in; }), function (e) { return e; });
                            if (advDefault.length > 1)
                                advDefault = distinct(lnkAdv_1.filter(function (lnk) { return lnk.Default; }).map(function (lnk) { return lnk.in; }), function (e) { return e; });
                            if (advDefault.length) {
                                found_1 = lnkAdv_1.filter(function (lnk) { return advDefault.includes(lnk.in) && lnk.DefaultBroadcastArea && (advDefault.length == 1 || lnk.Default); }).map(function (lnk) { return lnk.DefaultBroadcastArea; });
                                if (found_1.length)
                                    return [2 /*return*/, dispatchChanged_1(broadcastOptions.find(function (o) { return found_1.includes(o["@rid"]); }))]; // recherche de la zone de diff par défaut de la première régie trouvée
                                broadcastAdvDefault_1 = (_broadcastAreas$find = broadcastAreas_1.find(function (lnkB) { return advDefault.includes(lnkB.in) && lnkB.Default; })) === null || _broadcastAreas$find === void 0 ? void 0 : _broadcastAreas$find.out;
                                if (broadcastAdvDefault_1)
                                    return [2 /*return*/, dispatchChanged_1(broadcastOptions.find(function (o) { return o["@rid"] == broadcastAdvDefault_1; }))];
                            } // recherche des zones de diff par défaut du support
                            defaultElement = broadcastOptions.find(function (o) { return broadcastAreas_1.some(function (lnk) { return lnk.out == o["@rid"] && lnk.Default; }); });
                            dispatchChanged_1(defaultElement);
                            return [2 /*return*/];
                        });
                    }); };
                    return [4 /*yield*/, setDefault()];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5: return [2 /*return*/, broadcastOptions];
            }
        });
    });
}
export function SetGroup(data, ids, category, propertyName, other, setValue) {
    return __awaiter(this, void 0, void 0, function () { var property, propertyOther, options, _options$find, _group$Rid, _group; return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                property = data.ModelProperties[propertyName];
                propertyOther = data.ModelProperties[other];
                return [4 /*yield*/, Client.searchVertexTyped(ref_Groups, { Active: true, Category: category, IDs: ids !== null && ids !== void 0 ? ids : [], _strictParams: { Area: { "$elemMatch": { Class: ref_Advertisers.name, IDs: { $in: [data.Advertiser, null] } } } } })];
            case 1:
                options = _a.sent();
                options.push({ Name: Trad("other"), "@rid": otherRid });
                if (setValue)
                    if (!property && !propertyOther || property && !options.some(function (c) { return c["@rid"] == property; })) {
                        _group = (_options$find = options.find(function (v) { return v.Default; })) !== null && _options$find !== void 0 ? _options$find : options.find(function (v) { return v["@rid"] != otherRid; });
                        data.ModelProperties[propertyName] = (_group$Rid = _group === null || _group === void 0 ? void 0 : _group["@rid"]) !== null && _group$Rid !== void 0 ? _group$Rid : null;
                        if (data.ModelProperties[propertyName] && data.ModelProperties[propertyName] !== otherRid)
                            data.ModelProperties[other] = null;
                        else
                            data.ModelProperties[other] = "";
                    }
                return [2 /*return*/, options.sort(NameSort("last"))];
        }
    }); });
}
;
export function SetPerformancesKPIFromOJD(newData, oldData) {
    return __awaiter(this, void 0, void 0, function () {
        var _medias$find, medias, mediaPresse, _waves$, _waves, _waves2, _await$DataProvider$g, _newData$ModelPropert, _ojd$MiseEnDistributi, _ojd$DiffusionPayeeFr, _ojd$DiffusionTotaleF, _ojd$Abonnements, _oldData$ModelPropert, _oldData$KPIs, waves, error_2, ojd, _a, _ref, circulation, _circulation$KPIs$Rel, _circulation$KPIs$Pai, _circulation$KPIs$Tot, _circulation$KPIs$Sub;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, KPIsManagerCache.GetMedias()];
                case 1:
                    medias = _b.sent();
                    mediaPresse = medias === null || medias === void 0 ? void 0 : (_medias$find = medias.find(function (m) { return m.Code == "PR"; })) === null || _medias$find === void 0 ? void 0 : _medias$find['@rid'];
                    if (!(mediaPresse && newData.Media == mediaPresse && (!hasOwnProperty(newData.ModelProperties, "OjdYear") || oldData.Support != newData.Support || oldData.BroadcastArea != newData.BroadcastArea) && newData.Support && newData.BroadcastArea)) return [3 /*break*/, 10];
                    ConsoleDebug('Init/Update OJD');
                    waves = void 0;
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, DataProvider.getOJDWaves({ support: newData.Support, broadcastArea: newData.BroadcastArea })];
                case 3:
                    waves = _b.sent();
                    return [3 /*break*/, 5];
                case 4:
                    error_2 = _b.sent();
                    console.error(error_2);
                    Notify(Trad('cannot_retrieve_ojd_data'), 'warning');
                    waves = [];
                    return [3 /*break*/, 5];
                case 5:
                    newData.ModelProperties["OjdYear"] = (_waves$ = (_waves = waves) === null || _waves === void 0 ? void 0 : _waves[0]) !== null && _waves$ !== void 0 ? _waves$ : null;
                    _a = ((_waves2 = waves) === null || _waves2 === void 0 ? void 0 : _waves2.length);
                    if (!_a) return [3 /*break*/, 7];
                    return [4 /*yield*/, DataProvider.getOJD({ support: newData.Support, broadcastArea: newData.BroadcastArea, year: (_newData$ModelPropert = newData.ModelProperties) === null || _newData$ModelPropert === void 0 ? void 0 : _newData$ModelPropert["OjdYear"] })];
                case 6:
                    _a = ((_await$DataProvider$g = _b.sent()) === null || _await$DataProvider$g === void 0 ? void 0 : _await$DataProvider$g[0]);
                    _b.label = 7;
                case 7:
                    ojd = _a;
                    newData.KPIs["Release"] = (_ojd$MiseEnDistributi = ojd === null || ojd === void 0 ? void 0 : ojd.MiseEnDistribution) !== null && _ojd$MiseEnDistributi !== void 0 ? _ojd$MiseEnDistributi : 0;
                    newData.KPIs["PaidCirculation"] = (_ojd$DiffusionPayeeFr = ojd === null || ojd === void 0 ? void 0 : ojd.DiffusionPayeeFrance) !== null && _ojd$DiffusionPayeeFr !== void 0 ? _ojd$DiffusionPayeeFr : 0;
                    newData.KPIs["TotalCirculation"] = (_ojd$DiffusionTotaleF = ojd === null || ojd === void 0 ? void 0 : ojd.DiffusionTotaleFrance) !== null && _ojd$DiffusionTotaleF !== void 0 ? _ojd$DiffusionTotaleF : 0;
                    newData.KPIs["Subscriptions"] = (_ojd$Abonnements = ojd === null || ojd === void 0 ? void 0 : ojd.Abonnements) !== null && _ojd$Abonnements !== void 0 ? _ojd$Abonnements : 0;
                    if (!!newData.ModelProperties["OjdYear"]) return [3 /*break*/, 9];
                    return [4 /*yield*/, DataProvider.search(ref_Circulations.name, { Support: newData.Support, BroadcastArea: newData.BroadcastArea, Active: true })];
                case 8:
                    circulation = ((_ref = _b.sent()) !== null && _ref !== void 0 ? _ref : [])[0];
                    if (circulation) {
                        newData.KPIs["Release"] = (_circulation$KPIs$Rel = circulation.KPIs.Release) !== null && _circulation$KPIs$Rel !== void 0 ? _circulation$KPIs$Rel : 0;
                        newData.KPIs["PaidCirculation"] = (_circulation$KPIs$Pai = circulation.KPIs.PaidCirculation) !== null && _circulation$KPIs$Pai !== void 0 ? _circulation$KPIs$Pai : 0;
                        newData.KPIs["TotalCirculation"] = (_circulation$KPIs$Tot = circulation.KPIs.TotalCirculation) !== null && _circulation$KPIs$Tot !== void 0 ? _circulation$KPIs$Tot : 0;
                        newData.KPIs["Subscriptions"] = (_circulation$KPIs$Sub = circulation.KPIs.Subscriptions) !== null && _circulation$KPIs$Sub !== void 0 ? _circulation$KPIs$Sub : 0;
                    }
                    console.log("circulation", circulation);
                    _b.label = 9;
                case 9:
                    if (newData.ModelProperties["OjdYear"] != ((_oldData$ModelPropert = oldData.ModelProperties) === null || _oldData$ModelPropert === void 0 ? void 0 : _oldData$ModelPropert["OjdYear"]) || !compareObjects(extractSub((_oldData$KPIs = oldData.KPIs) !== null && _oldData$KPIs !== void 0 ? _oldData$KPIs : {}, ["Release", "PaidCirculation", "TotalCirculation", "Subscriptions"], 0), extractSub(newData.KPIs, ["Release", "PaidCirculation", "TotalCirculation", "Subscriptions"], 0)))
                        Notify(Trad("update_ojd"), "info");
                    _b.label = 10;
                case 10: return [2 /*return*/];
            }
        });
    });
}
export function OnSupportChange(model, oldData, newData) { newData.BroadcastArea = null; newData.AdvCompany_Com = null; newData.AdvCompany_Fin = null; newData.Currency = null; delete newData.ModelProperties["OjdYear"]; delete newData.ModelProperties["Dated"]; delete newData.ModelProperties["Reference"]; delete newData.ModelProperties["EditorialTheme"]; if (newData.ModelProperties["Periodicity"] != oldData.ModelProperties["Periodicity"]) {
    console.log("changeEndValue", newData.ModelProperties["Periodicity"]);
    changeEndValue(model, newData);
} }
