var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
export var CronType;
(function (CronType) {
    CronType["Import"] = "Import";
    CronType["MDReport"] = "MDReport";
    CronType["LEGO"] = "LEGO";
    CronType["ExportAuto"] = "ExportAuto";
    CronType["Notify"] = "Notify";
    CronType["GitScript"] = "GitScript";
    CronType["MediaSpecs"] = "MediaSpecs";
    CronType["Codipresse"] = "Codipresse";
})(CronType || (CronType = {}));
var ref_Crons = /** @class */ (function () {
    function ref_Crons() {
        this.Active = true;
        this.Name = "";
        this.Schedule = {
            Recurrence: {
                DayOfWeek: [1, 2, 3, 4, 5],
                Hour: 9,
                Minute: 30,
            },
        };
        this.CronType = CronType.Import;
        this.TaskParams = {};
    }
    return ref_Crons;
}());
export { ref_Crons };
var NotifyCrons = /** @class */ (function (_super) {
    __extends(NotifyCrons, _super);
    function NotifyCrons() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.CronType = CronType.Notify;
        _this.Schedule = {
            Planned: {
                Date: new Date(),
                LoopSecond: 3,
            },
        };
        _this.getParams = function () { return ({}); };
        return _this;
    }
    return NotifyCrons;
}(ref_Crons));
export { NotifyCrons };
var ImportCrons = /** @class */ (function (_super) {
    __extends(ImportCrons, _super);
    function ImportCrons() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.CronType = CronType.Import;
        _this.getParams = function () {
            return {
                ImportID: "#123:123",
                SourceType: "MAP",
            };
        };
        return _this;
    }
    return ImportCrons;
}(ref_Crons));
export { ImportCrons };
var MediaSpecsCronsParams = /** @class */ (function () {
    function MediaSpecsCronsParams() {
        this.API_token_url = "https://keycloak.mediaspecs.eu/realms/mediaspecs-fr/protocol/openid-connect/token";
        this.API_client_id = "ad-wanted";
        this.API_client_secret = "NdMJEqBQRQH7RU7kaHH2W6ZyCt9FraL1";
        this.AdwOne_serverHost = "http://localhost:5000/mediaspecs/";
        this.Adwone_header_authorization = "Er26E0vGGfxi3xTPME7xtw+q0TFYRWd6BOsEYBDixPEWHA5S4Ix0t6KEU8E+d5B/FQC4oB6xnYYrQ2u9j8r/fA==";
        this.ImportFormats = false;
        this.PageIndex = 0;
        this.PageSize = 20;
    }
    return MediaSpecsCronsParams;
}());
export { MediaSpecsCronsParams };
var MediaSpecsCrons = /** @class */ (function (_super) {
    __extends(MediaSpecsCrons, _super);
    function MediaSpecsCrons() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.CronType = CronType.MediaSpecs;
        _this.getParams = function () {
            return new MediaSpecsCronsParams();
        };
        return _this;
    }
    return MediaSpecsCrons;
}(ref_Crons));
export { MediaSpecsCrons };
var CodipresseCronsParams = /** @class */ (function () {
    function CodipresseCronsParams() {
        this.ScrapeData = false;
        this.LinkSupports = false;
        this.CodipresseXMLUrl = "https://www.tarifspresse.com/codipresse/xml?_format=xml";
        this.TarifsPresseSupportUrl = "https://www.tarifspresse.com/titre/";
        this.AdwOne_serverHost = "http://localhost:5000/";
        this.Adwone_username = "";
        this.Adwone_password = "";
    }
    return CodipresseCronsParams;
}());
export { CodipresseCronsParams };
var CodipresseCrons = /** @class */ (function (_super) {
    __extends(CodipresseCrons, _super);
    function CodipresseCrons() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.CronType = CronType.Codipresse;
        _this.getParams = function () {
            return new CodipresseCronsParams();
        };
        return _this;
    }
    return CodipresseCrons;
}(ref_Crons));
export { CodipresseCrons };
var MDRaportCrons = /** @class */ (function (_super) {
    __extends(MDRaportCrons, _super);
    function MDRaportCrons() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.CronType = CronType.MDReport;
        _this.getParams = function () {
            return {
                Recipients: ["toto@adresse.com", "tata@mail.com"],
                Date: {
                    Start: new Date().toString(),
                    End: new Date().toString(),
                }
            };
        };
        return _this;
    }
    return MDRaportCrons;
}(ref_Crons));
export { MDRaportCrons };
var LEGOCrons = /** @class */ (function (_super) {
    __extends(LEGOCrons, _super);
    function LEGOCrons() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.CronType = CronType.LEGO;
        _this.getParams = function () {
            return {
                Folder: "./exemple/",
                Customer: "#123:123",
                ArchivesFolder: "./exemple/archives/",
                ArchiveSize: 7,
                ExportsFilesName: {
                    Messages: "messages",
                    Campaigns: "campaigns",
                    Discounts: "discounts"
                },
                FTP: {
                    Host: "ftp.adwone.fr",
                    User: "dupont",
                    Password: "mot de passe",
                    Folder: "./exemple"
                }
            };
        };
        return _this;
    }
    return LEGOCrons;
}(ref_Crons));
export { LEGOCrons };
var ExportAutoCrons = /** @class */ (function (_super) {
    __extends(ExportAutoCrons, _super);
    function ExportAutoCrons() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.CronType = CronType.ExportAuto;
        _this.getParams = function () {
            return {
                Folder: "./exemple/",
                Customer: "#123:123",
                ArchivesFolder: "./exemple/archives/",
                ArchiveSize: 7,
                ExportsFilesName: {
                    Messages: "messages",
                    Campaigns: "campaigns",
                    Discounts: "discounts"
                },
                FTP: {
                    Host: "ftp.adwone.fr",
                    User: "dupont",
                    Password: "mot de passe",
                    Folder: "./exemple"
                }
            };
        };
        return _this;
    }
    return ExportAutoCrons;
}(ref_Crons));
export { ExportAutoCrons };
var GitScriptCrons = /** @class */ (function (_super) {
    __extends(GitScriptCrons, _super);
    function GitScriptCrons() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getParams = function () {
            return {
                scriptID: ''
            };
        };
        return _this;
    }
    return GitScriptCrons;
}(ref_Crons));
export { GitScriptCrons };
