var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { extendDataItem, mapTree, modifySubItems, removeItems } from "@progress/kendo-react-treelist";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Client } from "hub-lib/client/client.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ref_Groups } from "hub-lib/dto/client/ref_Groups.bin";
import { clone, propertyOf } from "hub-lib/tools.bin";
import { Trad } from "trad-lib";
export var subItemsField = "Children";
export var editField = "inEdit";
export var expendedField = "Expanded";
var initialState = { fetchedDataCategory: undefined, fetchedDataType: undefined, fetchedData: [], fetchedDataAdvertiserGroups: [], fetchedDataAdvertisers: [], data: [], loaded: false, inEdit: [] };
export var createNode = function (_ref) { var _el$childrenKey, _el$isNew; var el = _ref.el, parent = _ref.parent, data = _ref.data, childrenKey = _ref.childrenKey; var id = new Date().getTime().toString(); if (el !== null && el !== void 0 && el["@rid"]) {
    id = "".concat(el === null || el === void 0 ? void 0 : el["@rid"]).concat(parent ? "-" + parent : "");
} return __assign(__assign({}, el), { Children: el !== null && el !== void 0 && (_el$childrenKey = el[childrenKey]) !== null && _el$childrenKey !== void 0 && _el$childrenKey.length ? el[childrenKey].map(function (e) { return createNode({ el: data.find(function (f) { return e === f["@rid"]; }), parent: id, data: data, childrenKey: childrenKey }); }) : [], Selected: false, Expanded: false, id: id, isNew: el === undefined ? true : (_el$isNew = el["isNew"]) !== null && _el$isNew !== void 0 ? _el$isNew : false, inEdit: false, parent: parent }); };
function removeItem(state, el, idsField) { state.data = removeItems(idsField ? mapTree(state.data, subItemsField, function (item) {
    var _a;
    return item.id === el.parent ? __assign(__assign({}, item), (_a = {}, _a[idsField] = item[idsField].filter(function (e) { return e !== el["@rid"]; }), _a)) : item;
}) : state.data, subItemsField, function (i) { return i.id === el.id; }); state.inEdit = state.inEdit.filter(function (i) { return i.id !== el.id; }); }
export var save = createAsyncThunk('treeSlice/save', function (action, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, isNew, inEdit, itemToSave, area, advertiser, state, advertiserGroup, state, ids, state, _created$data, created, el, el;
    var _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = action.item, isNew = _a.isNew, inEdit = _a.inEdit, itemToSave = __rest(_a, ["isNew", "inEdit"]);
                delete itemToSave["Children"];
                if (itemToSave[propertyOf("Area")]) {
                    area = clone(itemToSave[propertyOf("Area")]);
                    advertiser = area.find(function (e) { return e.Class === ref_Advertisers.name; });
                    if (advertiser) {
                        state = thunkAPI.getState();
                        if (advertiser.IDs !== null && (!advertiser.IDs.length || advertiser.IDs.length === state.tree.fetchedDataAdvertisers.length)) {
                            advertiser.IDs = null;
                            itemToSave = __assign(__assign({}, itemToSave), (_b = {}, _b[propertyOf("Area")] = area, _b));
                        }
                    }
                    advertiserGroup = area.find(function (e) { return e.Class === ref_AdvertiserGroups.name; });
                    if (advertiserGroup) {
                        state = thunkAPI.getState();
                        if (advertiserGroup.IDs !== null && (!advertiserGroup.IDs.length || advertiserGroup.IDs.length === state.tree.fetchedDataAdvertiserGroups.length)) {
                            advertiserGroup.IDs = null;
                            itemToSave = __assign(__assign({}, itemToSave), (_c = {}, _c[propertyOf("Area")] = area, _c));
                        }
                    }
                }
                if (itemToSave[propertyOf("IDs")]) {
                    ids = clone(itemToSave[propertyOf("IDs")]);
                    state = thunkAPI.getState();
                    if (ids.length >= state.tree.fetchedData.length) {
                        itemToSave["IDs"] = null;
                    }
                }
                if (!isNew) return [3 /*break*/, 5];
                if (!(itemToSave.parent === undefined)) return [3 /*break*/, 2];
                return [4 /*yield*/, Client.createVertex(ref_Groups.name, itemToSave)];
            case 1:
                created = _d.sent();
                itemToSave = __assign(__assign({}, itemToSave), (created === null || created === void 0 ? void 0 : (_created$data = created.data) === null || _created$data === void 0 ? void 0 : _created$data.results));
                return [3 /*break*/, 4];
            case 2:
                el = action.data.find(function (e) { return e.id === itemToSave.parent; });
                return [4 /*yield*/, Client.updateVertex(ref_Groups.name, el)];
            case 3:
                _d.sent();
                _d.label = 4;
            case 4: return [3 /*break*/, 9];
            case 5:
                if (!(itemToSave.parent === undefined)) return [3 /*break*/, 7];
                return [4 /*yield*/, Client.updateVertex(ref_Groups.name, itemToSave)];
            case 6:
                _d.sent();
                return [3 /*break*/, 9];
            case 7:
                el = action.data.find(function (e) { return e.id === itemToSave.parent; });
                return [4 /*yield*/, Client.updateVertex(ref_Groups.name, el)];
            case 8:
                _d.sent();
                _d.label = 9;
            case 9: return [2 /*return*/, { data: mapTree(action.data, subItemsField, function (item) { return item.id === itemToSave.id ? itemToSave : item; }), inEdit: action.inEdit.filter(function (i) { return i.id !== itemToSave.id; }) }];
        }
    });
}); });
export var treeSlice = createSlice({ name: "tree", initialState: initialState, extraReducers: function (builder) {
        builder.addCase(save.fulfilled, function (state, action) { state.data = action.payload.data; state.inEdit = action.payload.inEdit; }), builder.addCase(save.rejected, function (state, err) { console.error(err); });
    }, reducers: { setState: function (state, action) { if (state.fetchedDataCategory === action.payload.fetchedDataCategory)
            Object.assign(state, action.payload); }, enterEdit: function (state, action) { state.inEdit = __spreadArray(__spreadArray([], state.inEdit, true), [extendDataItem(action.payload, subItemsField)], false); }, addRecord: function (state, action) { var node = createNode({ el: action.payload, data: state.fetchedData }); state.data = __spreadArray(__spreadArray([], state.data, true), [node], false); state.inEdit = __spreadArray(__spreadArray([], state.inEdit, true), [__assign({}, node)], false); }, addDuplicate: function (state, action) { var duplicate = clone(action.payload); delete duplicate["@rid"]; duplicate.isNew = true; duplicate.Name = "".concat(duplicate.Name, " (").concat(Trad("copy"), ")"); var node = createNode({ el: duplicate, data: state.fetchedData }); node.Children = duplicate.Children; state.data = __spreadArray(__spreadArray([], state.data, true), [node], false); state.inEdit = __spreadArray(__spreadArray([], state.inEdit, true), [__assign({}, node)], false); }, addChild: function (state, action) { var node = createNode({ parent: action.payload.id, data: state.fetchedData }); state.inEdit = __spreadArray(__spreadArray([], state.inEdit, true), [node], false); state.data = modifySubItems(mapTree(state.data, subItemsField, function (item) {
            var _a;
            return item.id === node.parent ? __assign(__assign({}, item), (_a = {}, _a[expendedField] = true, _a)) : item;
        }), subItemsField, function (item) { return item.id === node.parent; }, function (subItems) { return __spreadArray([node], subItems, true); }); }, cancel: function (state, action) { var inEdit = state.inEdit, data = state.data; if (action.payload.isNew) {
            removeItem(state, action.payload);
        }
        else {
            state.data = mapTree(data, subItemsField, function (item) { return item.id === action.payload.id ? inEdit.find(function (i) { return i.id === item.id; }) : item; });
            state.inEdit = inEdit.filter(function (i) { return i.id !== action.payload.id; });
        } }, remove: function (state, action) { var toDelete = new Set([]); var toUpdate = new Set([]); var _a = action.payload, selected = _a.selected, idsField = _a.idsField; for (var _i = 0, selected_1 = selected; _i < selected_1.length; _i++) {
            var el = selected_1[_i];
            if (el.parent && !toUpdate.has(el["@rid"])) {
                toUpdate.add(el.parent);
            }
            else {
                toDelete.add(el["@rid"]);
            }
            removeItem(state, el, idsField);
        } if (toDelete.size) {
            toDelete.forEach(function (e) { toUpdate.delete(e); });
            Client.deleteVertex(ref_Groups.name, Array.from(toDelete));
        } if (toUpdate.size) {
            Client.updateVertex(ref_Groups.name, state.data.filter(function (e) { return toUpdate.has(e.id); }));
        } }, onItemChange: function (state, action) { var field = action.payload.field; state.data = mapTree(state.data, subItemsField, function (item) {
            var _a;
            return item.id === action.payload.id ? extendDataItem(item, subItemsField, (_a = {}, _a[field] = action.payload.value, _a)) : item;
        }); }, onExpandedChange: function (state, action) { state.data = mapTree(state.data, subItemsField, function (item) {
            var _a, _b;
            return action.payload.includes(item.id) ? __assign(__assign({}, item), (_a = {}, _a[expendedField] = true, _a)) : __assign(__assign({}, item), (_b = {}, _b[expendedField] = false, _b));
        }); }, clear: function (state, action) { Object.assign(state, __assign(__assign({}, initialState), { fetchedDataCategory: action.payload })); }, updateIDs: function (state, action) { var newItem = {}; if (action.payload.Area) {
            var advertiserGroupIds_1 = action.payload.Area.filter(function (v) { return v["@class"] == ref_AdvertiserGroups.name; }).map(function (g) { return g["@rid"]; });
            var advertiserIds = action.payload.Area.filter(function (v) { return v["@class"] == ref_Advertisers.name && (!v["parent"] || !advertiserGroupIds_1.some(function (g) { return g == v["parent"][0]; })); }).map(function (a) { return a["@rid"]; });
            console.log("Filtered", advertiserGroupIds_1, advertiserIds);
            newItem[propertyOf("Area")] = [{ Class: ref_AdvertiserGroups.name, IDs: advertiserGroupIds_1 }, { Class: ref_Advertisers.name, IDs: advertiserIds }];
        }
        else if (action.payload.Items) {
            newItem[propertyOf("IDs")] = action.payload.Items.map(function (e) { return e["@rid"]; });
            newItem[subItemsField] = action.payload.Items.map(function (el) { return createNode({ el: el, data: state.fetchedData, parent: action.payload.id }); });
        } console.log("newItem", newItem); state.data = mapTree(state.data, subItemsField, function (item) { return item.id === action.payload.id ? extendDataItem(item, subItemsField, newItem) : item; }); } } }); // Action creators are generated for each case reducer function
export var setState = (_a = treeSlice.actions, _a.setState), enterEdit = _a.enterEdit, cancel = _a.cancel, addChild = _a.addChild, remove = _a.remove, onItemChange = _a.onItemChange, onExpandedChange = _a.onExpandedChange, addRecord = _a.addRecord, addDuplicate = _a.addDuplicate, clear = _a.clear, updateIDs = _a.updateIDs;
export var treeReducer = treeSlice.reducer;
